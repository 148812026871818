import React, { ChangeEvent, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { useAsyncOperationState } from '../../../../../dorian-shared/hooks/useAsyncOperationState';
import { MemoryIconUploader } from '../../../FileUploaders/MemoryIconUploader/MemoryIconUploader';
import { ImagePopoverPreview } from '../../../ImagePopoverPreview/ImagePopoverPreview';
import { MemoryIcon } from '../memoryBankTypes';
import { CustomMemoryIconMenu } from './CustomMemoryIconMenu';
import classes from './MemoryField.module.scss';

interface MemoryIconFieldProps extends React.ComponentProps<typeof Form.Control> {
  memoryIcons: MemoryIcon[];
  onSave: (image: File, label: string) => Promise<MemoryIcon>;
}

export function MemoryIconField(props: MemoryIconFieldProps) {
  const {
    id,
    value,
    disabled,
    isInvalid,
    onChange,
    memoryIcons,
    onSave,
    errorMessage,
    variant = 'light',
    delay = { show: 1000, hide: 100 },
    label = 'Icon',
    ...rest
  } = props;

  const [showDropdown, setShowDropdown] = useState(false);
  const [showMemoryIconUploader, setShowMemoryIconUploader] = useState(false);

  // const apiService = useApiService();
  const [, {
    isLoading,
    setToLoading,
    setToError,
    setToSuccess,
  }] = useAsyncOperationState();

  const handleOnSelect = (memoryIconId: string | null) => {
    if (!id) return;
    const event = {
      target: {
        id,
        value: memoryIconId ?? '',
      },
    };
    onChange?.(event as ChangeEvent<HTMLInputElement>);
  };

  const handleSave = async (image: File, labelName: string): Promise<MemoryIcon> => {
    setToLoading();
    try {
      const memoryIconResponse = await onSave(image, labelName);
      handleOnSelect(memoryIconResponse.id.toString());
      setToSuccess();
      setShowMemoryIconUploader(false);
      return memoryIconResponse;
    } catch (error) {
      setToError();
      throw error;
    }
  };

  const handleLabelValidate = (labelName: string) => {
    const newLabel = labelName.trim().toLowerCase();
    if (!newLabel) return 'Label is required';
    if (newLabel.length < 1) return 'Label is too short';
    if (newLabel.length > 36) return 'Label is too long';
    return memoryIcons.find((icon) => icon.label.trim().toLowerCase() === newLabel) ? 'Label already exists' : undefined;
  };

  const selectIconText = disabled ? '-' : 'Select icon';
  const memoryIcon = memoryIcons.find((icon) => Number(icon.id) === Number(value));
  const iconName = memoryIcon?.label ?? selectIconText;

  return (
    <>
      <Form.Group className="position-relative">
        <Form.Label
          htmlFor={id}
          className={classes.memoryLabel}
        >
          {label}
        </Form.Label>

        {disabled
          ? '-'
          : (
            <Dropdown
              onToggle={(isOpened) => setShowDropdown(isOpened)}
              show={showDropdown}
              {...rest}
            >
              <ImagePopoverPreview previewImageUrl={memoryIcon?.url ?? ''} delay={delay}>
                <Dropdown.Toggle
                  id={`${id}-dropdown`}
                  variant={variant}
                  disabled={disabled}
                  title={iconName}
                  className="w-100 h-100 overflow-hidden text-truncate direction-rtl text-right form-control rounded"
                >
                  {iconName}
                </Dropdown.Toggle>
              </ImagePopoverPreview>
              <Dropdown.Menu
                as={CustomMemoryIconMenu}
                className="w-100 mw-75 overflow-auto"
                onAddNewClick={() => setShowMemoryIconUploader(true)}
                style={{ maxHeight: '50vh' }}
              >
                {memoryIcons.map((icon) => (
                  <ImagePopoverPreview previewImageUrl={icon.url} key={icon.id}>
                    <Dropdown.Item
                      active={icon.id === Number(value)}
                      eventKey={icon.id}
                      onSelect={handleOnSelect}
                    >
                      {`${icon.label}`}
                    </Dropdown.Item>
                  </ImagePopoverPreview>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        {/* Needed for form validation */}
        <Form.Control
          type="hidden"
          value={value}
          isInvalid={isInvalid}
          {...rest}
        />
        <Form.Control.Feedback type="invalid" tooltip>
          {errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
      {showMemoryIconUploader && (
        <MemoryIconUploader
          imageSrc=""
          onSave={handleSave}
          onCancel={() => setShowMemoryIconUploader(false)}
          isLoading={isLoading}
          onLabelValidate={handleLabelValidate}
        />
      )}
    </>
  );
}
