import React, { useState } from 'react';
import {
  Button, Col, Modal, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../../../api';
import classNames from './DeleteVideoModal.module.scss';

/* TODO
  Received the following warning after deletion:
    Warning: Can't perform a React state update on an unmounted component. 
    This is a no-op, but it indicates a memory leak in your application. 
    To fix, cancel all subscriptions and asynchronous tasks in a useEffect 
    cleanup function.
*/

export function DeleteVideoModal({
  video,
  onDelete,
  onCancel,
}) {
  const useDeleteVideo = (onDeleteVideo) => {
    const [isLoading, setIsLoading] = useState(false);

    const deleteVideo = () => {
      setIsLoading(true);
      api.delete(`/v1/video/${video.id}`)
        .then(() => {
          onDeleteVideo();
        })
        .finally(() => setIsLoading(false));
    };
    return { deleteVideo, isLoading };
  };

  const { deleteVideo, isLoading } = useDeleteVideo(onDelete);

  const handleCancelClick = () => onCancel(video);

  return (
    <Modal
      show
      size="md"
    >
      <Modal.Header className={classNames.modalHeader}>
        <Modal.Title className={classNames.modalTitle}>
          Delete video
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={classNames.modalTitle}>
        <Row className={classNames.videoTitle}>
          <Col>
            {video.title}
          </Col>
        </Row>
        <Row className={classNames.videoText}>
          <Col>
            Do you really want to delete this video?
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button
            type="reset"
            variant="secondary"
            className={classNames.button}
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            className={classNames.button}
            onClick={deleteVideo}
          >
            {isLoading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            )}
            Delete
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
