import React, {
  createContext, useContext, useMemo,
} from 'react';
import { useVideos } from './useVideos';

export const VideosContext = createContext(null);

const useUploadVideoContext = () => useContext(VideosContext);

export const useRefetchVideo = () => {
  const { refetchVideo } = useUploadVideoContext();
  return refetchVideo;
};

export function VideosProvider({ children }) {
  const {
    videos,
    isLoading,
    refetchVideo,
    currentPage,
    setCurrentPage,
    totalCount,
    handleSearchSubmit,
    searchTerm,
  } = useVideos();

  const contextValue = useMemo(
    () => ({
      currentPage,
      setCurrentPage,
      totalCount,

      videos,
      isLoading,
      refetchVideo,

      handleSearchSubmit,
      searchTerm,
    }),
    [
      currentPage,
      setCurrentPage,
      totalCount,

      videos,
      isLoading,
      refetchVideo,

      handleSearchSubmit,
      searchTerm,
    ],
  );

  return (
    <VideosContext.Provider value={contextValue}>
      { children }
    </VideosContext.Provider>
  );
}
