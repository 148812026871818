import React, { useState } from 'react';
import {
  Alert,
  Button, Col, Form, Modal, Row, Spinner,
} from 'react-bootstrap';
import { v4 as uuidV4 } from 'uuid';
import { api } from '../../../../api';
import { useRefetchVideo } from '../VideosProvider/VideosProvider';
import classNames from './UploadVideo.module.scss';

const acceptFileTypes = '.mp4';

const requestConfig = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};

export function UploadVideo({
  onUpload,
  onCancel,
}) {
  const refetchVideo = useRefetchVideo();
  const [loading, setLoading] = useState(false);
  const [validatedForm, setValidatedForm] = useState(false);

  const [videoTitle, setVideoTitle] = useState('');
  const [videoFile, setVideoFile] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidatedForm(true);
      return;
    }

    const formData = new FormData();
    formData.append('video', videoFile);
    formData.append('alias', uuidV4());
    formData.append('title', videoTitle);

    setLoading(true);

    api.post('/v1/video', formData, requestConfig)
      .then(() => {
        setLoading(false);
        refetchVideo();
        onUpload();
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const hideErrorMessage = () => {
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  // TODO: Ask for format and min/max size file
  const handleUploadFile = (event) => {
    hideErrorMessage();
    const file = event.target.files[0];

    if (!file) {
      setErrorMessage('File not selected.');
      return;
    }

    if (file.type.indexOf('mp4') === -1) {
      setErrorMessage('Available only *.mp3 format');
      return;
    }

    if (file.size > 100000000) {
      setErrorMessage('Maximum file size is 100MB');
      return;
    }

    setVideoFile(file);
    if (!videoTitle) {
      setVideoTitle(file.name.replace(/\.[^\\/.]+$/, ''));
    }
  };

  return (
    <Modal
      show
      size="md"
    >
      <Form
        noValidate
        validated={validatedForm}
        className={classNames.form}
        onSubmit={handleFormSubmit}
      >
        <Modal.Header>
          <Modal.Title
            className={classNames.modalTitle}
          >
            Upload video
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={classNames.modalBody}
        >
          <Row>
            <Form.Group
              as={Col}
              sm="12"
              controlId="video-file"
              className={classNames.formGroup}
            >
              <Form.Label className={classNames.formLabel}>
                Video File
              </Form.Label>
              <Form.Control
                required
                name="video-file"
                type="file"
                placeholder="Image"
                accept={acceptFileTypes}
                onChange={handleUploadFile}
              />
              <Form.Control.Feedback type="invalid">
                Please choose a video.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} sm="8" controlId="video-title">
              <Form.Label className={classNames.formLabel}>
                Title
              </Form.Label>
              <Form.Control
                required
                value={videoTitle}
                name="video-title"
                type="text"
                size="sm"
                placeholder="Video title"
                pattern="^([A-Za-z]|[0-9]|_|-|)+$"
                onChange={(event) => {
                  hideErrorMessage();
                  setVideoTitle(event.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please use characters A-Z,a-z,0-9,_,-
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button
              type="reset"
              variant="secondary"
              className={classNames.button}
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="primary"
              className={classNames.button}
            >
              {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              )}
              Upload
            </Button>
          </div>
          {
            errorMessage && (
              <Col md={12}>
                <Alert variant="danger">
                  {errorMessage}
                </Alert>
              </Col>
            )
          }
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
