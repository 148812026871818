/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
/* eslint-disable-next-line max-classes-per-file */
import { EpisodeStorePublicInterface } from '@dorian/creation-tools-ui';
import async from 'async';
import classNames from 'classnames/bind';
import { cloneDeep, isEqual } from 'lodash-es';
import React, { Component, createRef, PureComponent } from 'react';
import {
  Alert, Badge, Form, Spinner,
} from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { DraggableCore } from 'react-draggable';
import { Redirect } from 'react-router-dom';
import IconCursor from '../../../assets/images/flag.png';
import {
  AchievementsContextProvider,
} from '../../../contexts/AchievementsContext/AchievementsContext';
import {
  PerformanceRanksContextProvider,
} from '../../../contexts/PerformanceRanksContext/PerformanceRanksContext';
import { SendNotificationsFor } from '../../../dorian-shared/types/book/book';
import { StepTypes } from '../../../dorian-shared/types/branch/BranchStep';
import { IS_MAC } from '../../../helpers/os';
import { UndoRedo } from '../../../helpers/UndoRedo';
import { bugTracker } from '../../../services/bugTracker/BugTrackerService';
import { logger } from '../../../services/loggerService/loggerService';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { showToast } from '../../ui/utils';
import { isDecisionStep } from '../../utils/branchUtils';
import { PremiumIpDisabledApprovedEdit } from '../../utils/premiumIpDisabledApprovedEdit';
import { PremiumIpDisabledEdit } from '../../utils/premiumIpDisabledEdit';
import { isAchievementsEnabled, isMemoryBankEnabled } from '../../utils/userActionUtils';
import { AchievementsModal } from '../AchievementsModal/AchievementsModal';
import { BookCover } from '../Book/BookCover';
import { ChapterRestoreModal } from '../Book/ChapterRestoreModal';
import { MemoryBankModal } from '../Book/MemoryBank/MemoryBankModal';
import { getMemoryBankSlotDataById } from '../Book/MemoryBank/memoryBankUtils';
import { Characters } from '../Characters';
import { ImportTextModal } from '../ImportTextModal/ImportTextModal';
import { Locations } from '../Locations';
import { PerformanceRanksModal } from '../PerformanceRanksModal/PerformanceRanksModal';
import { Export } from '../Stories/Export';
import { BookTagsModal } from './BookTagsModal';
import { fetchIsStoryAnalyticsVisible } from './BranchAnalytics/utils';
import { AddBranch } from './BranchEdit/AddBranch';
import { clearStepData } from './BranchEdit/Steps/CopyPastStep';
import { isCheckStep } from './BranchEdit/Steps/StepTypeCheck/StepTypeCheck';
import { BranchItem } from './BranchItem';
import { CancelAddBranch } from './CancelAddBranch';
import { CoverEditor } from './CoverEditor';
import { DetachablePreview } from './DetachablePreview/DetachablePreview';
import { HelpSection } from './HelpSection';
import { InfoPanel } from './InfoPanel';
import { NodePositioningModal } from './NodePositioningModal';
import { NotFoundModal } from './NotFoundModal';
import { BranchesListPanel } from './Panel/BranchesListPanel';
import { PublishModal, PublishType } from './PublishModal/PublishModal';
import { SaveAsTemplate } from './SaveAsTemplate';
import { createSelectable, SelectableGroup } from './Selectable';
import { SharedSettingsPanel } from './SharedSettingsPanel';
import { ShareEmulatorLinkModal } from './ShareEmulatorLinkModal/ShareEmulatorLinkModal';
import styles from './StoryBranches.scss';
import { ValidatePanel } from './ValidatePanel';

export const BRANCH_LINES_TYPES = {
  Link: 'link',
  Branch: 'branch',
  SwitchTrue: 'switch_true',
  SwitchFalse: 'switch_false',
};

export const BRANCH_LINES_CLASSES = {
  link: 'line_dashed',
  switchTrue: 'line_dashed line_switch_true',
  switchFalse: 'line_dashed line_switch_false',
  branch: 'line_solid',
};

function getClassNameByLineType(lineType) {
  switch (lineType) {
    case BRANCH_LINES_TYPES.Link:
      return BRANCH_LINES_CLASSES.link;
    case BRANCH_LINES_TYPES.SwitchTrue:
      return BRANCH_LINES_CLASSES.switchTrue;
    case BRANCH_LINES_TYPES.SwitchFalse:
      return BRANCH_LINES_CLASSES.switchFalse;
    case BRANCH_LINES_TYPES.Branch:
    default:
      return BRANCH_LINES_CLASSES.branch;
  }
}

const cs = classNames.bind(styles);

function SComponent(props) {
  const {
    children, selectableRef, isSelecting, selectionItems, selectableId,
  } = props;
  const isNodeSelected = selectionItems.includes(selectableId);

  return (
    <div
      ref={selectableRef}
      className={`
      ${isSelecting && 'selecting'}
      ${isNodeSelected && 'selected'}
    `}
    >
      {children}
    </div>
  );
}

const SelectableComponent = createSelectable(SComponent);

class BranchesLine extends PureComponent {
  render() {
    const {
      angle, top, left, toID, length, fromID, type,
    } = this.props;

    const style = {
      position: 'absolute',
      left: `${left}px`,
      top: `${top}px`,
      width: `${length}px`,
      transform: `rotate(${angle}deg)`,
      transformOrigin: '0 0',
    };

    const className = getClassNameByLineType(type);
    return (
      <div style={style} className={`line_${fromID}_${toID} ${className}`} />
    );
  }
}

function isLiveEditDisabled(role, group) {
  if (!role) return true;

  return ['live', 'liveprompt'].includes(group);
}

function showDangerAlert(text, timeBeforeDisappearing = 1000) {
  ReactDOM.render(
    <Alert variant="danger" className="mx-3 my-3">
      {text}
    </Alert>,
    document.getElementById('errorsBox'),
  );
  setTimeout(() => {
    ReactDOM.render(null, document.getElementById('errorsBox'));
  }, timeBeforeDisappearing);
}

const handlePreviewExit = (reason) => {
  switch (reason) {
    case 'pass-to-publish-dialog':
      // TODO: publish
      return;
    default:
      // TODO: https://dorian.atlassian.net/browse/DOR-3171
      showDangerAlert('Thanks for playing');
  }
};

export class StoryBranches extends Component {
  constructor(props) {
    super(props);
    const { auth } = this.props;

    const willPreviewPanelOpenedFromStorage = sessionStorage.getItem('previewPanelSate');
    const previewPanelState = willPreviewPanelOpenedFromStorage
      ? JSON.parse(willPreviewPanelOpenedFromStorage)
      : true;

    this.state = {
      stepTypes: [],
      branch: [],
      story: {},
      editBranchData: null,
      addBranchActive: false,
      showLocations: false,
      branchesDragItems: [],
      branchesScale: localStorage.getItem('zoom') !== null ? JSON.parse(localStorage.getItem('zoom')) : 1,
      keyDownFlag: {},
      snapToGrid: true,
      showGrid: true,
      lastEdit: null,
      notFound: false,
      modeEdit: false,
      modeAdminStyle: 'd-none',
      modeEditStyle: 'd-none',
      validatePanel: false,
      lastLocation: null,
      branchesWrapper: { x: 5000, y: 5000 },
      handleSize: { x: 200, y: 160 },
      boxSize: { x: 25, y: 25 },
      grid: 25,
      infoPanel: false,
      branchesListPanel: false,
      dragStart: false,
      limits: {},
      newBranchId: null,
      newStories: null,
      selectionItems: [],
      savePositionLoading: false,
      warning: 0,
      error: 0,
      sharedSettingsPanel: false,
      preview: previewPanelState,
      showImagePath: false,
      authUser: auth.getUser(),
      branchOnNameEdit: false,
      NodePositioningModal: false,
      previewPanel: previewPanelState,
      destinationNodeId: null,
      sourceNodeId: null,
      sourceNodeIdType: null,
      shareModal: false,
      GoLiveLoading: false,
      GoLiveModal: false,
      saveTemplateModal: false,
      editCoverActive: false,
      ConfirmationCancelAddBranch: false,
      AddBranchEdit: false,
      showEditBookTags: false,
      isDuplicateMode: false,
      showBookCover: false,
      showChapterRestore: false,
      HelpPanel: false,
      redirect: null,
      isAnalyticsVisible: false,
      scrollToNode: false,
      storePublicInterface: new EpisodeStorePublicInterface(),
      showMemoryBank: false,
      showAchievementsModal: false,
      showImportTextModal: false,
      // Remove this after refactoring
      reFetchMemoriesTrigger: 0,
      showPerformanceRanksModal: false,
    };
    this.optionsRef = createRef();
    this.selectionRef = createRef();
    this.mainContent = createRef();
    this.mainContentWrapper = createRef();
    this.undoRedoBranchPosition = null;
    this.scrollInterval = null;
  }

  reRenderPreviewBranch = () => {
    const { storePublicInterface } = this.state;
    if (!storePublicInterface) {
      return;
    }
    storePublicInterface.internalStore.setNavHistoryStack([...storePublicInterface.internalStore.navHistoryStack]);
  };

  updatePreviewCurrentBranch = (branchId) => {
    const { branch: branches, storePublicInterface, reFetchMemoriesTrigger } = this.state;
    const branchById = branches.find((branch) => branch.id === branchId);

    if (!storePublicInterface) {
      return;
    }

    // with disabled caching for engine this will trigger refetch of data on CT side
    if (!branchById || branchById.title === 'intro') {
      storePublicInterface.setStackStartBranch(branchId);
      this.setState({ reFetchMemoriesTrigger: reFetchMemoriesTrigger + 1 });
      return;
    }

    if (!storePublicInterface.internalStore.isLoading) {
      storePublicInterface.internalStore.goAheadWithStory(branchId);
    } else {
      // TODO: Use smt tanStack Query or retry libraries
      let attempts = 0;
      const interval = setInterval(() => {
        if (!storePublicInterface.internalStore.isLoading) {
          clearInterval(interval);
          storePublicInterface.internalStore.goAheadWithStory(branchId);
        }
        if (attempts > 30) {
          clearInterval(interval);
        }
        attempts += 1;
      }, 500);
    }
  };

  updatePreviewAllBranches = () => {
    const { story, storePublicInterface } = this.state;

    if (!storePublicInterface) {
      return;
    }

    const storyUuid = story.internal_uuid;
    api.get(`/v1/preview/${storyUuid}/branches`).then((response) => {
      storePublicInterface.updateEpisodeBranches(response.data.branches);
      this.reRenderPreviewBranch();
    });
  };

  handleBranchDelete = () => {
    const { branch } = this.state;
    const firstBranchId = branch[0].id;
    this.updateBranch();
    this.updatePreviewCurrentBranch(firstBranchId);
  };

  handleSelectionFinish = (newSelectedNodes) => {
    if (newSelectedNodes.length === 0) {
      return;
    }

    const newSelectedNodeIds = newSelectedNodes.map((node) => node.id);
    this.setState({ selectionItems: newSelectedNodeIds });
  };

  handleSelectionClear = (selectItems = []) => {
    this.setSelectionItems(selectItems);
  };

  handleOnError = (error) => {
    bugTracker().reportError(error);
    showToast({ textMessage: 'Error while trying to perform action' });
  };

  isElementContainsMainContentWrapperElement = (element) => this.mainContentWrapper.current?.hasChildNodes(element);

  calculateScrollFactor = (originalScale, newValue) => {
    const adjustedScaleDifference = 1 + (newValue - originalScale);
    let scaleFactor = 1;

    // Calculate the adjusted value based on the new scale. This value is used to adjust scrolling.
    let adjustedValue = adjustedScaleDifference > 1 ? newValue / (1 - (newValue - originalScale)) : adjustedScaleDifference;

    // Check for conditions where the new scale is less than 1 and there is a non-zero scale difference.
    // Or if newValue is not 1, adjustedScaleDifference is greater than 1, and there's a non-zero scale difference.
    // In these cases, adjust the scale factor and set the adjusted value to the new value directly.
    if ((adjustedScaleDifference < 1 && newValue - adjustedScaleDifference !== 0)
      || (newValue !== 1 && adjustedScaleDifference > 1 && newValue - adjustedScaleDifference !== 0)) {
      scaleFactor = 1 / (1 + (originalScale - 1));
      adjustedValue = newValue;
    }
    return { scaleFactor, adjustedValue };
  };

  onWheel = (e) => {
    const { branchesScale, wheelLastTime } = this.state;
    if (!this.isElementContainsMainContentWrapperElement(e.target)) return;

    if (!e.ctrlKey && !e.metaKey && !e.altKey) return;

    const now = new Date().getTime();
    e.preventDefault();

    if (wheelLastTime && now - wheelLastTime < 250) return;

    let newScale = branchesScale + (e.deltaY > 0 ? -0.25 : 0.25);
    newScale = Math.max(0.25, Math.min(newScale, 1));

    if (newScale === branchesScale) return;

    const mainContentElement = this.mainContent.current;
    const { scaleFactor, adjustedValue } = this.calculateScrollFactor(branchesScale, newScale);
    mainContentElement.scrollTo((mainContentElement.scrollLeft * scaleFactor) * adjustedValue, (mainContentElement.scrollTop * scaleFactor) * adjustedValue);

    this.setState({
      wheelLastTime: now,
      branchesScale: newScale,
    });
  };

  onMouseDown = (e) => {
    const { keyDownFlag } = this.state;

    if (!this.isElementContainsMainContentWrapperElement(e.target)) {
      return;
    }

    /* e.button => 0 is left, 1 is middle, 2 is right */
    if (e.button === 2 || e.shiftKey && e.button === 0) {
      this.mainContent.current.addEventListener('mousemove', this.onMouseMove);
      const newKeyFlag = { ...keyDownFlag, Shift: true };
      this.setState({ keyDownFlag: newKeyFlag });
    }
  };

  onMouseUp = (e) => {
    const { keyDownFlag } = this.state;

    this.mainContent.current.removeEventListener('mousemove', this.onMouseMove);

    /* e.button => 0 is left, 1 is middle, 2 is right */
    if (e.button === 2 && keyDownFlag.Shift) {
      const newKeyFlag = { ...keyDownFlag, Shift: false };
      this.setState({ keyDownFlag: newKeyFlag });
    }
  };

  onBlur = () => {
    this.setState({ keyDownFlag: {} });
  };

  onContextMenu = (e) => {
    if (this.isElementContainsMainContentWrapperElement(e.target)) {
      e.preventDefault();
    }
  };

  keyFunctionDown = (e) => {
    const { keyDownFlag: prevKeyDownFlag, savePositionLoading } = this.state;

    const keyDownFlag = { ...prevKeyDownFlag };

    keyDownFlag.Shift = e.shiftKey;
    keyDownFlag.Control = e.ctrlKey;
    keyDownFlag.Meta = e.metaKey;
    keyDownFlag.Alt = e.altKey;

    const isInput = document.activeElement.nodeName === 'INPUT' || document.activeElement.nodeName === 'TEXTAREA';
    if (isInput) {
      return;
    }

    const additionalKey = IS_MAC ? e.metaKey : e.ctrlKey;

    if (e.key.toLowerCase() === 'z' && additionalKey) {
      e.preventDefault();
      if (this.undoRedoBranchPosition?.canUndo() && !savePositionLoading) {
        const nodes = this.undoRedoBranchPosition.getCurrentState()?.prev ?? [];
        this.undoRedoBranchPosition.undo();
        this.savePosition(nodes);

        const selectionItems = nodes.map((node) => node.id);
        this.setSelectionItems(selectionItems);
      }
      return;
    }

    if (e.key.toLowerCase() === 'y' && additionalKey) {
      e.preventDefault();
      if (this.undoRedoBranchPosition?.canRedo() && !savePositionLoading) {
        const nodes = this.undoRedoBranchPosition.redo()?.next ?? [];
        this.savePosition(nodes);

        const selectionItems = nodes.map((node) => node.id);
        this.setSelectionItems(selectionItems);
      }
      return;
    }

    switch (e.key) {
      case 'Control':
        keyDownFlag[e.key] = true;
        this.setState({ keyDownFlag });
        break;
      case 'Alt':
        e.preventDefault();
        keyDownFlag[e.key] = true;
        this.setState({ keyDownFlag });
        break;
      case ' ':
        e.preventDefault();
        if (keyDownFlag.Shift) {
          return;
        }
        keyDownFlag.Shift = true;
        this.setState({ keyDownFlag });
        break;
      case 'Shift':
        keyDownFlag.Shift = true;
        this.setState({ keyDownFlag });
        break;
      case 'Command':
      case 'Meta':
        keyDownFlag.Meta = true;
        this.setState({ keyDownFlag });
        break;
      default:
        break;
    }
  };

  keyFunctionUp = (e) => {
    const { keyDownFlag: prevKeyDownFlag } = this.state;

    const keyDownFlag = { ...prevKeyDownFlag };

    keyDownFlag.Shift = e.shiftKey;
    keyDownFlag.Control = e.ctrlKey;
    keyDownFlag.Meta = e.metaKey;
    keyDownFlag.Alt = e.altKey;

    switch (e.key) {
      case 'Control':
        keyDownFlag[e.key] = false;
        this.setState({ keyDownFlag });
        break;
      case 'Alt':
        keyDownFlag[e.key] = false;
        e.preventDefault();
        this.setState({ keyDownFlag });
        break;
      case ' ':
      case 'Shift':
        keyDownFlag[e.key] = false;
        this.setState({ keyDownFlag });
        break;
      case 'Command':
      case 'Meta':
        keyDownFlag.Meta = false;
        this.setState({ keyDownFlag });
        break;
      default:
        break;
    }
  };

  errCount = (warning, error) => {
    this.setState({
      warning,
      error,
    });
  };

  scrollToBranch = (branchId, force = false) => {
    const { branchesScale, lastEdit, scrollToNode } = this.state;

    if (lastEdit === branchId && !force) {
      return;
    }
    this.setState({ lastEdit: branchId });

    if (!scrollToNode && !force) {
      return;
    }

    const elm = document.getElementById(`branches_${branchId}`);
    if (elm) {
      const elmTop = (elm.offsetTop - 30) * branchesScale;
      const elmLeft = (elm.offsetLeft - 30) * branchesScale;
      this.mainContent.current.scrollTo(elmLeft, elmTop);
    }
  };

  validateAction = (branch, key) => {
    const { validatePanel, branch: branchFromState, infoPanel } = this.state;

    this.setState({
      lastEdit: branch || null,
    });

    if (branch) {
      this.scrollToBranch(branch, true);
    }

    if (key) {
      if (key === 'story') {
        this.setState({
          validatePanel: !validatePanel,
        }, () => {
          if (this.optionsRef) {
            this.optionsRef.current.show();
          }
        });
      }

      if (key === 'info') {
        this.setState({
          validatePanel: !validatePanel,
          infoPanel: !infoPanel,
        });
      }

      if (key === 'book_cover') {
        this.setState({
          validatePanel: !validatePanel,
        }, () => {
          this.actionBookCover();
        });
      }

      if (key === 'story_cover') {
        this.setState({
          validatePanel: !validatePanel,
        }, () => {
          this.actionEditCover();
        });
      }

      if (key === 'locations') {
        this.setState({
          validatePanel: !validatePanel,
        });
        this.initLocations();
        return;
      }

      if (key === 'branches') {
        this.setState({
          validatePanel: !validatePanel,
        });
        const b = branchFromState.find((e) => e.id === branch);
        this.initAddBranch(b);
      }
      if (key === 'characters') {
        this.actionEditCharacters();
      }
      if (key === 'book_tags') {
        this.setState({
          validatePanel: !validatePanel,
        }, () => {
          this.setState({
            showEditBookTags: true,
          });
        });
      }
    }
  };

  clearSelectionUsingRef = () => {
    this.selectionRef.current?.clearSelection();
  };

  componentDidMount() {
    const { story } = this.state;

    clearStepData();
    this.loadSettings();
    this.loadBranchList();
    this.loadStory();
    document.addEventListener('blur', this.onBlur);
    document.addEventListener('visibilitychange', this.onBlur);
    document.addEventListener('contextmenu', this.onContextMenu, { passive: false });
    document.addEventListener('wheel', this.onWheel, { passive: false });
    document.addEventListener('mousedown', this.onMouseDown, { passive: false });
    document.addEventListener('mouseup', this.onMouseUp, { passive: false });
    document.addEventListener('keydown', this.keyFunctionDown, { passive: false });
    document.addEventListener('keyup', this.keyFunctionUp, { passive: false });
    window.addEventListener('storage', this.updateStorage, { passive: false });
    localStorage.removeItem(`modeEdit-${story.internal_uuid}`);
    this.newStorySteps();
  }

  newStorySteps() {
    const { match } = this.props;
    const { branch } = this.state;

    const a = JSON.parse(sessionStorage.getItem('newStories'));
    if (a && String(a.id) === String(match.params.id)) {
      this.setState({
        newStories: String(a.id),
      });
      switch (a.step) {
        case 1:
          this.initLocations();
          break;
        case 2:
          break;
        case 3:

          if (branch.length === 1) {
            this.initAddBranch(branch[0]);
          } else {
            sessionStorage.removeItem('newStories');
            this.setState({
              editBranchData: null,
              addBranchActive: false,
              newStories: null,
            });
          }
          break;
        default:
          sessionStorage.removeItem('newStories');
          break;
      }
    }
  }

  updateStorage = (e) => {
    if (e.key === 'newStories') {
      this.newStorySteps();
    }
  };

  mGrid() {
    const { branch: branches } = this.state;
    const newBranchesWrapper = { x: 0, y: 0 };
    branches.forEach((branch) => {
      newBranchesWrapper.x = Math.max(newBranchesWrapper.x, branch.x + 5000);
      newBranchesWrapper.y = Math.max(newBranchesWrapper.y, branch.y + 5000);
    });
    this.setState({ branchesWrapper: newBranchesWrapper });
  }

  componentDidUpdate(prevProps, prevState) {
    const { auth, match, context } = this.props;
    const {
      lastEdit, branch, authUser, story, keyDownFlag, branchesScale, preview, reFetchMemoriesTrigger,
    } = this.state;

    const { memoryBankContext, memoryIconsContext } = context;

    if (prevState.lastEdit !== lastEdit && lastEdit) {
      localStorage.setItem(`lastEdit-${match.params.id}`, lastEdit);
    }

    if (prevState.branchesScale !== branchesScale) {
      localStorage.setItem('zoom', JSON.stringify(branchesScale));
    }

    if (
      prevProps.auth !== auth
        || prevProps.auth.getUser().id !== auth.getUser().id
        || prevProps.auth.getUser().role !== auth.getUser().role
    ) {
      this.setState({ authUser: auth.getUser() });
    }

    if (prevState.branch !== branch) {
      this.mGrid();
    }

    if (prevState.story.story_role !== story.story_role) {
      let modeEdit = true;
      let modeEditStyle = 'd-none';

      if (authUser.role !== 'admin' && story.story_role === 'viewer') {
        modeEdit = false;
        modeEditStyle = 'd-none';
      }

      if (authUser.role === 'admin' && story.story_role !== 'owner') {
        modeEdit = localStorage.getItem('modeEdit') !== null
          ? !JSON.parse(localStorage.getItem('modeEdit'))
          : false;
        modeEditStyle = 'd-block';
      }

      this.setState({
        modeEdit,
        modeEditStyle,
        modeAdminStyle: authUser.role === 'admin' ? 'd-block' : 'd-none',
      });

      localStorage.setItem(`modeEdit-${story.internal_uuid}`, !modeEdit);

      fetchIsStoryAnalyticsVisible(authUser, story.book.id, story.group)
        .then((isStoryAnalyticsVisible) => {
          this.setState({ isAnalyticsVisible: isStoryAnalyticsVisible });
        });
    }

    if (prevState.keyDownFlag.Shift !== keyDownFlag.Shift) {
      if (keyDownFlag.Shift) {
        this.mainContent.current.setAttribute('style', 'cursor: grab');
        document.body.style.userSelect = 'none';
      } else {
        this.mainContent.current.setAttribute('style', 'cursor: default');
        document.body.style.userSelect = 'auto';
      }
    }

    if (prevState.preview !== preview) {
      sessionStorage.setItem('previewPanelSate', preview);
    }

    if (prevState.story?.book?.id !== story?.book?.id) {
      memoryBankContext.setBookId(story?.book?.id);
      memoryIconsContext.setBookId(story?.book?.id);
    }
  }

  componentWillUnmount() {
    const { story } = this.state;
    document.removeEventListener('blur', this.onBlur);
    document.removeEventListener('visibilitychange', this.onBlur);
    document.removeEventListener('contextmenu', this.onContextMenu, false);
    document.removeEventListener('wheel', this.onWheel, false);
    document.removeEventListener('mousedown', this.onMouseDown, false);
    document.removeEventListener('mouseup', this.onMouseUp, false);
    document.removeEventListener('keydown', this.keyFunctionDown, false);
    document.removeEventListener('keyup', this.keyFunctionUp, false);
    window.removeEventListener('storage', this.updateStorage);
    localStorage.removeItem(`modeEdit-${story.internal_uuid}`);
    sessionStorage.removeItem('newStories');
  }

  updateGoLive = () => {
    const { validatePanel, story } = this.state;
    this.setState({
      GoLiveLoading: true,
    });

    let url = `/v1/books/${story.book.id}/chapters/${story.id}/golive`;
    if (story.group === 'prompt') {
      url = `/v1/books/${story.book.id}/chapters/${story.id}/makepromptlive`;
    } else if (story.book.original) {
      url = `/v1/books/${story.book.id}/chapters/${story.id}/submit`;
    }

    api.post(url)
      .then(() => {
        this.setState({
          GoLiveModal: false,
          GoLiveLoading: false,
          redirect: `/book/${story.book.id}`,
        });
      })
      .catch((error) => {
        this.setState({
          GoLiveModal: false,
          GoLiveLoading: false,
          validatePanel: String(error.response.status) !== '418' ? validatePanel : true,
        }, () => {
          if (String(error.response.status) !== '418') {
            bugTracker().reportError(error);
            showToast({ textMessage: 'Error while trying to go live' });
          }
        });
      });
  };

  loadSettings() {
    const { authUser } = this.state;

    async.parallel({
      settings: (callback) => {
        api.get('/v1/settings')
          .then((res) => {
            callback(null, res.data.settings);
          }).catch((error) => {
            callback(error, null);
          });
      },
      types: (callback) => {
        api.get('/v1/steps/types')
          .then((res) => {
            callback(null, res.data.types);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (err, res) => {
      try {
        if (!err) {
          // TODO: Extract to utils
          const memoryChoiceStepTypeIds = [StepTypes.Check, StepTypes.Remember];
          const stepTypes = isMemoryBankEnabled(authUser)
            ? res.types
            : res.types.filter((type) => !memoryChoiceStepTypeIds.includes(type.id));

          this.setState({
            stepTypes,
            limits: res.settings.limits,
            showImagePath: res.settings.preferences.show_character_image_path.value === 'on',
          });
        }
      } catch (e) {
        // TODO: report to sentry
      }
    });
  }

  loadBranchList = async (NewBranchId, val) => {
    const { match, context } = this.props;
    const { apiService } = context;

    this.setState({ savePositionLoading: true });
    try {
      const branches = await apiService.fetchStoryBranchesByEpisodeId(match.params.id);

      // eslint-disable-next-line max-len
      const newValID = val && val.id && val.arr && val.arr.number && val.arr.number === 1 ? val.id : null;
      const lastEditFromStorage = localStorage.getItem(`lastEdit-${match.params.id}`);
      const firstNode = branches[0]?.id ?? null;
      const prevLastEdit = JSON.parse(lastEditFromStorage ?? 'null');
      const isPrevNodeExist = branches.some((branch) => branch.id === prevLastEdit);
      const newLastEdit = isPrevNodeExist ? prevLastEdit : firstNode;

      if (!this.undoRedoBranchPosition) {
        this.undoRedoBranchPosition = new UndoRedo([[]]);
      }

      this.setState({
        newBranchId: newValID || null,
        addBranchActive: false,
        branch: branches,
        destinationNodeId: null,
        sourceNodeId: null,
        sourceNodeIdType: null,
        lastEdit: newLastEdit,
      });

      this.updatePreviewCurrentBranch(newLastEdit);

      if (val && val.arr && val.arr.number && val.arr.number !== 1) {
        const value = val.arr;
        value.number -= 1;
        this.initAddBranch(value);
      }
    } catch (error) {
      bugTracker().reportError(error);
      showToast({ textMessage: 'Error while trying to load branches' });
    } finally {
      this.setState({ savePositionLoading: false });
    }
  };

  loadStory() {
    const { match, context } = this.props;
    const { memoryBankContext, bookLocationContext } = context;

    api.get(`/v1/stories/${match.params.id}`)
      .then((res) => {
        this.setState({
          story: res.data.story,
        });
        memoryBankContext.fetchMemories();
        bookLocationContext.fetchBookLocations(res.data.story.book.id);
      })
      .catch((error) => {
        bugTracker().reportError(error);
        showToast({ textMessage: 'Error while trying to load story' });
      });
  }

  handleStart(event, data, nodeToMove) {
    const {
      branchesScale,
      selectionItems: selectionItemIds,
      branch: branches,
      savePositionLoading,
      lastEdit,
    } = this.state;

    if (savePositionLoading) {
      return;
    }

    const wrapBox = this.mainContentWrapper.current.getBoundingClientRect();
    const wrapBoxLeft = wrapBox ? wrapBox.left : 0;
    const wrapBoxTop = wrapBox ? wrapBox.top : 0;

    const isNodeToMoveExist = selectionItemIds.some((selectionItemId) => selectionItemId === nodeToMove.id);

    const nodesToMove = selectionItemIds.map((selectedNodeId) => branches.find((node) => node.id === selectedNodeId));

    const isCtrlPressed = event.ctrlKey || event.metaKey;

    if (isCtrlPressed) {
      return;
    }

    const newSelectionItems = isNodeToMoveExist ? selectionItemIds : [nodeToMove.id];

    if (!isEqual(newSelectionItems, selectionItemIds)) {
      this.setSelectionItems(newSelectionItems);
    }

    if (newSelectionItems.length === 1) {
      this.setState(
        { lastEdit: nodeToMove.id },
        () => {
          if (lastEdit !== nodeToMove.id) {
            this.updatePreviewCurrentBranch(nodeToMove.id);
          }
        },
      );
    }
    this.setState({
      dX: ((event.clientX - wrapBoxLeft) / branchesScale) - nodeToMove.x,
      dY: ((event.clientY - wrapBoxTop) / branchesScale) - nodeToMove.y,
      branchesDragItems: isNodeToMoveExist ? nodesToMove : [nodeToMove],
    });
    this.mainContent.current.addEventListener('mousemove', this.onMouseMove);
  }

  getSnapToGridPosition = (x, y) => {
    const { grid: cellSize } = this.state;

    const snapToGridX = Math.round(x / cellSize) * cellSize;
    const snapToGridY = Math.round(y / cellSize) * cellSize;
    return {
      x: snapToGridX,
      y: snapToGridY,
    };
  };

  calculateNodePosition(uiX, uiY, node, pickedNodeToMove) {
    const {
      branchesScale, dX, dY, snapToGrid,
    } = this.state;

    const dragNodeLeftPosition = (uiX / branchesScale) - dX;
    const dragNodeTopPosition = (uiY / branchesScale) - dY;
    const isCurrentDragNode = node.id === pickedNodeToMove.id;
    const offsetPositionX = isCurrentDragNode ? 0 : pickedNodeToMove.x - node.x;
    const offsetPositionY = isCurrentDragNode ? 0 : pickedNodeToMove.y - node.y;
    const preLeftPosition = dragNodeLeftPosition - offsetPositionX;
    const preTopPosition = dragNodeTopPosition - offsetPositionY;

    const x = preLeftPosition >= 0 ? preLeftPosition : 0;
    const y = preTopPosition >= 0 ? preTopPosition : 0;

    if (snapToGrid) {
      const snapToGridPosition = this.getSnapToGridPosition(x, y);
      return {
        x: snapToGridPosition.x,
        y: snapToGridPosition.y,
      };
    }

    return { x, y };
  }

  handleDrag(event, data, nodeToMove) {
    const {
      branchesDragItems,
      dragStart,
    } = this.state;

    const isCtrlPressed = event.ctrlKey || event.metaKey;

    if (isCtrlPressed) {
      return;
    }

    // To prevent dragging if distance is less than 10px
    const position = this.calculateNodePosition(data.x, data.y, nodeToMove, nodeToMove);
    const distance = Math.abs(position.x - nodeToMove.x) + Math.abs(position.y - nodeToMove.y);
    if (distance < 10) {
      return;
    }

    if (!dragStart) {
      this.setState({ dragStart: true });
    }

    branchesDragItems.forEach((movingNode) => {
      if (movingNode.id) {
        const newPosition = this.calculateNodePosition(data.x, data.y, movingNode, nodeToMove);
        const dragNodeElement = document.getElementById(`branchesDragItem-${movingNode.id}`);
        dragNodeElement.setAttribute('style', `display: block; left: ${newPosition.x}px; top: ${newPosition.y}px;z-index:99999;`);
        dragNodeElement.classList.add('opacity');
      }
    });
  }

  handleStop(event, data, dragNode) {
    const {
      dragStart,
      branchesDragItems: movedNodes,
      branch: branches,
    } = this.state;
    this.mainContent.current.removeEventListener('mousemove', this.onMouseMove);
    this.stopScroll();

    if (!dragStart) {
      return;
    }

    const movedNodesWithNewPosition = movedNodes.map(
      (branchesDragItem) => {
        const newPosition = this.calculateNodePosition(data.x, data.y, branchesDragItem, dragNode);
        return { ...branchesDragItem, ...newPosition };
      },
    );

    const filteredNodes = branches.filter((branch) => movedNodesWithNewPosition.some((node) => node.id === branch.id));

    const doQueue = {
      prev: cloneDeep(filteredNodes),
      next: movedNodesWithNewPosition,
    };

    this.undoRedoBranchPosition?.do(doQueue);
    this.savePosition(movedNodesWithNewPosition);

    this.setState({
      dragStart: false,
      branchesDragItems: [],
    });
  }

  moveDragNodesToSavedPosition(movedNodes) {
    const { branch: allNodes } = this.state;

    movedNodes.forEach(
      (movedNode) => {
        const nodeToMove = allNodes.find((node) => node.id === movedNode.id);
        // TODO: To think about refactoring
        nodeToMove.x = movedNode.x;
        nodeToMove.y = movedNode.y;

        const movedNodeElement = document.getElementById(`branches_${movedNode.id}`);
        if (movedNodeElement) {
          movedNodeElement.setAttribute('style', `position: absolute; left: ${movedNode.x}px; top: ${movedNode.y}px;`);
          movedNodeElement.classList.remove('opacity');
        }
      },
    );
    this.mGrid();
  }

  setSelectionItems = (selectionItems) => {
    this.clearSelectionUsingRef();
    this.selectionRef.current?.selectByIds(selectionItems);
    this.setState({ selectionItems });
  };

  savePosition(nodes) {
    const { match } = this.props;

    this.setState({ savePositionLoading: true });

    const prepareNodesForPayload = nodes.map(
      (node) => ({
        id: node.id,
        data: {
          x: node.x,
          y: node.y,
        },
      }),
    );

    const payload = {
      branches: prepareNodesForPayload,
    };

    this.moveDragNodesToSavedPosition(nodes);
    api.patch(`/v1/stories/${match.params.id}/branches`, payload)
      .then(() => {
        // TODO: update status
        logger.info('[StoryBranches] Branches position saved', payload);
      })
      .catch((error) => {
        showToast({ textMessage: `Branches position save failed${error.message}` });
        if (this.undoRedoBranchPosition?.canUndo()) {
          const restoredBranchPositions = this.undoRedoBranchPosition.getCurrentState()?.prev ?? [];
          this.undoRedoBranchPosition.undo();
          this.moveDragNodesToSavedPosition(restoredBranchPositions);

          const selectionItems = restoredBranchPositions.map((node) => node.id);
          this.setSelectionItems(selectionItems);
        }
      })
      .finally(() => this.setState({ savePositionLoading: false }));
  }

  addBranches = (val) => {
    const { branchesScale } = this.state;

    const value = {
      x: this.mainContent.current.scrollLeft / branchesScale,
      y: this.mainContent.current.scrollTop / branchesScale,
      number: val,
    };
    this.initAddBranch(value);
  };

  initAddBranch = (branchData) => {
    this.setState({
      editBranchData: branchData ?? null,
      addBranchActive: true,
    });
  };

  cancelAddBranch = () => {
    const { match } = this.props;
    let { newStories } = this.state;

    if (newStories === match.params.id) {
      newStories = null;
      sessionStorage.removeItem('newStories');
    }
    this.setState({
      editBranchData: null,
      addBranchActive: false,
      ConfirmationCancelAddBranch: false,
      AddBranchEdit: false,
      newStories,
    });
  };

  branchesLinesObj(fromID, toID, type, index) {
    const fromElm = document.getElementById(`branches_${fromID}`);
    const toElm = document.getElementById(`branches_${toID}`);

    if (!fromElm || !toElm) {
      return null;
    }

    const wf = fromElm.clientWidth / 2;
    const hf = fromElm.clientHeight / 2;
    const wt = toElm.clientWidth / 2;
    const ht = toElm.clientHeight / 2;

    const dy = (toElm.offsetTop + ht) - (fromElm.offsetTop + hf);
    const dx = (toElm.offsetLeft + wt) - (fromElm.offsetLeft + wf);

    const angle = (Math.atan2(dy, dx) * 180) / Math.PI;
    const length = Math.sqrt(dx * dx + dy * dy);

    const left = fromElm.offsetLeft + wf;
    const top = fromElm.offsetTop + hf;

    return (
      <BranchesLine
        key={index}
        type={type}
        fromID={fromID}
        toID={toID}
        left={left}
        top={top}
        length={length}
        angle={angle}
      />
    );
  }

  branchesLines = () => {
    const { branch } = this.state;

    return branch.map((object, i) => {
      const fromID = object.id;
      if (object.links !== undefined && object.links.length > 0) {
        return object.links.map((_link, j) => this.branchesLinesObj(
          fromID,
          _link,
          BRANCH_LINES_TYPES.Link,
          j,
        ));
      }
      if (object.switch && object.switch.length > 0) {
        return object.switch.map((_switch, j) => {
          const switchValue = _switch.value === 'true';
          const lineType = switchValue
            ? BRANCH_LINES_TYPES.SwitchTrue : BRANCH_LINES_TYPES.SwitchFalse;

          return this.branchesLinesObj(
            fromID,
            _switch.gotoBranchId,
            lineType,
            j,
          );
        });
      }
      if (object.gotoBranchId !== null) {
        return this.branchesLinesObj(fromID, object.gotoBranchId, BRANCH_LINES_TYPES.Branch, i);
      }
      return null;
    });
  };

  updateBranch = () => {
    this.loadBranchList();
  };

  findCascade = (nodes, res, node) => {
    const gotoBranchId = node.gotoBranchId ? [Number(node.gotoBranchId)] : [];
    const gotoLinks = node.links?.map((el) => Number(el)) ?? [];
    const gotoSwitch = node.switch?.map((el) => Number(el.gotoBranchId)) ?? [];

    const gotoNodeIds = [...gotoBranchId, ...gotoLinks, ...gotoSwitch];
    gotoNodeIds.forEach((gotoNodeId) => {
      const nextNode = nodes.find((el) => el.id === gotoNodeId);
      if (typeof nextNode !== 'undefined' && !res.includes(nextNode.id)) {
        res.push(nextNode.id);
        this.findCascade(nodes, res, nextNode);
      }
    });
  };

  findCascadeNodes = (data, start) => {
    const nodes = [];
    const startNode = data.find((el) => el.id === start);
    nodes.push(startNode.id);
    this.findCascade(data, nodes, startNode);
    return nodes;
  };

  selectCascade = (branchId) => {
    const { branch } = this.state;
    const ids = this.findCascadeNodes(branch, branchId);
    this.setSelectionItems(ids);
  };

  duplicateBranchsLoading = (position) => {
    const { match } = this.props;
    const { selectionItems } = this.state;

    this.setState({ savePositionLoading: true });

    const data = {};
    data.branches = selectionItems;
    data.position = position;
    api.post(`/v1/stories/${match.params.id}/branches/duplicate`, data)
      .then((res) => {
        this.setSelectionItems(res.data.branches);
        const lastEditNode = res.data.branches[res.data.branches.length - 1];

        this.setState({
          savePositionLoading: false,
          lastEdit: lastEditNode,
          isDuplicateMode: false,
        }, () => {
          this.loadBranchList();
        });
      })
      .then(this.updatePreviewAllBranches)
      .catch(() => {
        this.setState({
          savePositionLoading: false,
          isDuplicateMode: false,
        });
      });
  };

  duplicateBranchs = () => {
    this.setState({ isDuplicateMode: true });
  };

  sharedSettingsUpdate = () => {
    const { sharedSettingsPanel } = this.state;

    this.setState({
      sharedSettingsPanel: !sharedSettingsPanel,
    });
  };

  onBranchNameEdit = (val) => {
    this.setState({ branchOnNameEdit: val });
  };

  branchesItems() {
    const { match } = this.props;
    const {
      branch: branches,
      modeEdit,
      newBranchId,
      lastEdit,
      limits,
      keyDownFlag,
      handleSize,
      destinationNodeId,
      story,
      savePositionLoading,
      branchesScale,
      authUser,
      sourceNodeId,
      isAnalyticsVisible,
      selectionItems,
      branchOnNameEdit,
    } = this.state;

    const { context } = this.props;
    const { memoryBankContext } = context;

    const storyId = match.params.id;
    const isAnotherKeysPressed = keyDownFlag.Alt || keyDownFlag.Control || keyDownFlag.Meta;
    const disabled = (keyDownFlag.Shift && !isAnotherKeysPressed)
        || !modeEdit
        || PremiumIpDisabledEdit(authUser.role, story.book, story.group)
        || PremiumIpDisabledApprovedEdit(authUser.role, story.book)
        || isLiveEditDisabled(authUser.role, story.group)
        || !!sourceNodeId;

    return branches.map((branch) => {
      const { x } = branch;
      const { y } = branch;
      const handleStyle = x !== null ? {
        position: 'absolute',
        left: x < 0 ? 0 : x,
        top: y < 0 ? 0 : y,
        width: handleSize.x,
      } : {
        backgroundColor: 'rgba(0, 0, 0, .1)',
        width: handleSize.x,
      };

      const isDecisionPoint = isDecisionStep(branch);
      const decisionPoint = isDecisionPoint ? 'handle_branchesDecsionPoint' : '';
      const isSelected = selectionItems.includes(branch.id);
      const isLastEdit = lastEdit === branch.id && !isSelected;
      const handleLastEdit = isLastEdit ? 'handleLastEdit' : '';

      const dragObjClassName = `.handle_${branch.id}`;
      const dragObjClass = `handle handle_${branch.id} ${decisionPoint} ${handleLastEdit}`;

      const isCheck = isCheckStep(branch);
      const memorySlotData = getMemoryBankSlotDataById(memoryBankContext.memories, branch.check);

      // Used in published or read only stories
      const handleBranchClick = () => {
        if (isLiveEditDisabled(authUser.role, story.group) || !modeEdit) {
          this.setState({ lastEdit: branch.id });
          this.updatePreviewCurrentBranch(branch.id);
        }
      };

      return (
        <DraggableCore
          key={branch.id}
          handle={dragObjClassName}
          disabled={disabled || branchOnNameEdit}
          position={null}
          // grid={[1, 1]}
          onStart={(e, data) => this.handleStart(e, data, branch)}
          onDrag={(e, data) => this.handleDrag(e, data, branch)}
          onStop={(e, data) => this.handleStop(e, data, branch)}
        >
          {/* eslint-disable-next-line max-len */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className={dragObjClass}
            style={handleStyle}
            id={`branches_${branch.id}`}
            onClick={handleBranchClick}
            onDoubleClick={() => this.initAddBranch(branch)}
          >
            <SelectableComponent
              {...this.props}
              selectableId={branch.id}
              selectionItems={selectionItems}
            >
              <BranchItem
                onDeleteSuccess={this.handleBranchDelete}
                branchNode={branch}
                actionEditBranch={this.initAddBranch}
                update={this.updateBranch}
                duplicate={this.duplicateBranchs}
                selectCascade={this.selectCascade}
                storyId={storyId}
                storyGroup={story ? story.group : null}
                disabled={disabled}
                limits={limits}
                newBranchId={newBranchId || null}
                clearSelection={this.handleSelectionClear}
                onError={this.handleOnError}
                onEdit={this.onBranchNameEdit}
                mainContent={this.mainContent}
                destinationNodeId={destinationNodeId}
                branchesScale={branchesScale}
                sourceNodeId={sourceNodeId}
                savePositionLoading={savePositionLoading}
                updateLoading={(val) => {
                  this.setState({
                    savePositionLoading: val,
                  });
                }}
                updateConnection={(id, type) => {
                  this.setState({
                    sourceNodeId: id,
                    sourceNodeIdType: type,
                    destinationNodeId: null,
                  });
                }}
                isAnalyticsVisible={isAnalyticsVisible}
                isCheckStep={isCheck}
                memorySlotData={memorySlotData}
                isLastEdit={isLastEdit}
              />
            </SelectableComponent>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className={`hoverConnection ${sourceNodeId ? 'd-block' : 'd-none'}`}
              onClick={() => {
                this.setState({
                  destinationNodeId: branch.id,
                });
              }}
            />
          </div>
        </DraggableCore>
      );
    });
  }

  branchesDragItem() {
    const { authUser, branchesDragItems } = this.state;

    if (!branchesDragItems || branchesDragItems.length < 1) {
      return null;
    }
    return branchesDragItems.map((branchNode) => {
      const isDecisionPoint = isDecisionStep(branchNode);
      const decisionPoint = isDecisionPoint ? 'handle_branchesDecsionPoint' : '';
      const isCheck = isCheckStep(branchNode);
      return (
        <div
          key={branchNode.id}
          id={`branchesDragItem-${branchNode.id}`}
          className={`branchesDragItem ${decisionPoint}`}
        >
          <BranchItem
            onDeleteSuccess={this.handleBranchDelete}
            branchNode={branchNode}
            disabled
            user={authUser}
            isCheckStep={isCheck}
          />
        </div>
      );
    });
  }

  initLocations() {
    this.setState({
      showLocations: true,
    });
  }

  validatePanelHide = () => {
    this.setState({
      validatePanel: false,
    });
  };

  sharedSettingsPanelHide = () => {
    this.setState({
      sharedSettingsPanel: false,
    });
  };

  infoPanelHide = () => {
    this.setState({
      infoPanel: false,
    });
  };

  branchesListPanelHide = () => {
    this.setState({
      branchesListPanel: false,
    });
  };

  handleDoubleClickItem(e) {
    const {
      snapToGrid,
      branchesScale,
      isDuplicateMode,
      grid,
      story,
      savePositionLoading,
    } = this.state;

    if (savePositionLoading) {
      return;
    }

    if (['live', 'liveprompt'].includes(story.group)) {
      return;
    }
    const wrapBox = this.mainContentWrapper.current.getBoundingClientRect();
    const wrapBoxLeft = wrapBox ? wrapBox.left : 0;
    const wrapBoxTop = wrapBox ? wrapBox.top : 0;
    const snapToGridX = snapToGrid === true ? grid : 1;
    const snapToGridY = snapToGrid === true ? grid : 1;
    const obj = {
      // eslint-disable-next-line max-len
      x: Math.round(((e.clientX - wrapBoxLeft - (snapToGridX / 2)) / branchesScale) / snapToGridX) * snapToGridX,
      // eslint-disable-next-line max-len
      y: Math.round(((e.clientY - wrapBoxTop - (snapToGridY / 2)) / branchesScale) / snapToGridY) * snapToGridY,
      number: 1,
    };

    if (isDuplicateMode) {
      this.duplicateBranchsLoading(obj);
      return;
    }

    this.initAddBranch(obj);
  }

  startScroll = (side, e) => {
    clearInterval(this.scrollInterval);
    const mainContent = this.mainContent.current;
    if (!side || !mainContent) {
      return;
    }
    const offset = 5;
    const interval = 7;

    this.scrollInterval = setInterval(() => {
      switch (side) {
        case 'right':
          mainContent.scrollBy({
            left: offset,
            behavior: 'instant',
          });
          break;
        case 'left':
          mainContent.scrollBy({
            left: -offset,
            behavior: 'instant',
          });
          break;
        case 'top':
          mainContent.scrollBy({
            top: -offset,
            behavior: 'instant',
          });
          break;
        case 'bottom':
          mainContent.scrollBy({
            top: offset,
            behavior: 'instant',
          });
          break;
        default:
          break;
      }
      mainContent.dispatchEvent(e);
    }, interval);
  };

  stopScroll = () => {
    clearInterval(this.scrollInterval);
    this.scrollInterval = null;
  };

  onMouseMove = async (e) => {
    const { keyDownFlag, dragStart } = this.state;

    const mainContentElement = this.mainContent.current;

    /*
    0: No button or un-initialized
    1: Primary button (usually the left button)
    2: Secondary button (usually the right button)
    4: Auxiliary button (usually the mouse wheel button or middle button)
    8: 4th button (typically the "Browser Back" button)
    16 : 5th button (typically the "Browser Forward" button)
     */
    if (keyDownFlag.Shift === true) {
      if (e.buttons === 1 || e.buttons === 2) {
        mainContentElement.scrollTo({
          top: mainContentElement.scrollTop - e.movementY,
          left: mainContentElement.scrollLeft - e.movementX,
          behavior: 'instant',
        });
      }
      return;
    }

    if (!dragStart) {
      return;
    }

    const mainContentRect = mainContentElement.getBoundingClientRect();

    const willScrollBottom = window.innerHeight - e.clientY < 100;
    const willScrollLeft = e.clientX - mainContentRect.x < 100 && mainContentElement.scrollLeft > 0;
    const willScrollRight = (mainContentRect.x + mainContentRect.width) - e.clientX < 100;
    const willScrollTop = e.clientY - mainContentRect.y < 150 && mainContentElement.scrollTop > 0;
    const willScrollStop = !willScrollBottom && !willScrollLeft && !willScrollRight && !willScrollTop && this.scrollInterval;

    if (willScrollStop) {
      this.stopScroll();
      return;
    }

    if (willScrollBottom && !this.scrollInterval) {
      this.startScroll('bottom', e);
    }

    if (willScrollLeft && !this.scrollInterval) {
      this.startScroll('left', e);
    }
    if (willScrollRight && !this.scrollInterval) {
      this.startScroll('right', e);
    }

    if (willScrollTop && !this.scrollInterval) {
      this.startScroll('top', e);
    }
  };

  onLocationSelected = (locationId) => {
    this.setState({
      lastLocation: locationId,
    });
  };

  validateBadge = () => {
    const { error, warning } = this.state;

    return (
      <>
        Validate
        <span className="boxBadge">
          {(warning || warning > 0) && (
          <Badge pill variant="warning">
            {warning || 0}
          </Badge>
          )}
          {(error || error > 0) && (
          <Badge pill variant="danger">
            {error || 0}
          </Badge>
          )}
        </span>
      </>
    );
  };

  importAsTextBadge = () => (
    <>
      Import as text
      <span className="boxBadge">
        <Badge pill variant="danger">
          Beta
        </Badge>
      </span>
    </>
  );

  // TODO: Need to implement
  // setLastEdit = (branchId) => {
  //   if (!branchId) {
  //     return;
  //   }
  //
  //   this.setState({
  //     lastEdit: branchId,
  //   });
  //
  //   this.scrollToBranch(branchId);
  // };

  actionEditCharacters = () => {
    this.setState({
      editCharactersActive: true,
    });
  };

  actionEditCover = () => {
    const { editCoverActive } = this.state;

    this.setState({
      editCoverActive: !editCoverActive,
    });
  };

  actionBookCover = () => {
    const { showBookCover } = this.state;

    this.setState({
      showBookCover: !showBookCover,
    });
  };

  actionChapterRestore = () => {
    const { showChapterRestore } = this.state;

    this.setState({
      showChapterRestore: !showChapterRestore,
    });
  };

  editCover() {
    const {
      authUser, editCoverActive, modeEdit, story,
    } = this.state;

    if (editCoverActive) {
      return (
        <CoverEditor
          obj={this.state}
          onHide={this.actionEditCover}
          viewOnly={
            (
              (
                authUser.role !== 'admin'
                && story.story_role === 'viewer'
              )
              || !modeEdit
              || PremiumIpDisabledEdit(authUser.role, story.book, story.group)
              || PremiumIpDisabledApprovedEdit(authUser.role, story.book)
              || isLiveEditDisabled(authUser.role, story.group)
            )
          }
          update={() => {
            this.loadStory();
          }}
        />
      );
    }
    return null;
  }

  characters() {
    const {
      modeEdit, editCharactersActive, limits, authUser, story, showImagePath,
    } = this.state;

    const { context } = this.props;

    if (editCharactersActive) {
      return (
        <Characters
          user={authUser}
          book={story.book}
          id={String(story.book.id)}
          title={story.book.title}
          show={editCharactersActive}
          onHide={() => {
            this.setState({ editCharactersActive: false });
            this.loadBranchList();
            this.updatePreviewAllBranches();
          }}
          disabledEdit={
            !modeEdit
            || PremiumIpDisabledEdit(authUser.role, story.book, story.group)
            || PremiumIpDisabledApprovedEdit(authUser.role, story.book)
            || isLiveEditDisabled(authUser.role, story.group)
          }
          disabled={
            (
              !modeEdit
              || (
                authUser.role !== 'admin'
                && story.book.book_role !== 'owner'
                && story.book.book_role !== 'editor'
                && story.book.book_role !== 'co-author'
              )
            )
          }
          limits={limits}
          showImagePath={showImagePath}
          context={context}
        />
      );
    }
    return null;
  }

  previewClose = () => this.setState({ preview: false });

  handlePreviewUpdate = (val) => this.setState({ previewPanel: val });

  goLiveTitle = () => {
    const { story } = this.state;
    if (story && (story.book && !!story.book.original)) {
      return 'Submit';
    }
    if (story && story.live_count === 0) {
      return 'Release in the app';
    }
    if (story && story.live_count !== 0) {
      return 'Update in the app';
    }
    return '...';
  };

  livePromptTitle = () => {
    const { story } = this.state;
    if (story.group === 'liveprompt' || story.group === 'prompt') {
      return 'Publish Prompt';
    }
    return this.goLiveTitle();
  };

  handleAnyBranchUpdate = (maybeNewBranchInfo) => {
    const { match } = this.props;
    const { newStories } = this.state;
    if (newStories === match.params.id) {
      sessionStorage.removeItem('newStories');
      this.setState({
        newStories: null,
      });
    }
    this.loadBranchList(null, maybeNewBranchInfo);
  };

  handleBranchUpdate = (branchId) => {
    this.updatePreviewCurrentBranch(branchId);
    this.reRenderPreviewBranch();
    this.handleAnyBranchUpdate();
  };

  handleBranchCreate = (maybeNewBranchInfo) => {
    this.updatePreviewAllBranches();
    this.handleAnyBranchUpdate(maybeNewBranchInfo);
  };

  addBranch = () => {
    const {
      lastLocation,
      branch,
      ConfirmationCancelAddBranch,
      stepTypes,
      addBranchActive,
      story,
      limits,
      AddBranchEdit,
      editBranchData,
      modeEdit,
      authUser,
    } = this.state;

    const { context } = this.props;
    const { memoryBankContext } = context;
    const { memories } = memoryBankContext;

    if (addBranchActive === true) {
      return (
        <>
          <AddBranch
            branchesGroup={story.group}
            modeEdit={modeEdit}
            user={authUser}
            actionEditBranch={this.initAddBranch}
            branch={branch}
            story={story}
            storyId={this.props.match.params.id}
            data={editBranchData}
            stepTypes={stepTypes}
            show={addBranchActive}
            edit={(e) => {
              this.setState({
                AddBranchEdit: e,
              });
            }}
            addBranchEdit={AddBranchEdit}
            onHide={() => {
              if (
                (authUser.role === 'admin' && !modeEdit)
                || (authUser.role !== 'admin' && story.book.book_role !== 'owner' && story.book.book_role !== 'editor' && story.book.book_role !== 'co-author')
                || !AddBranchEdit
                || (
                  story
                  && (story.book
                    && !!story.book.original
                    && ['live', 'liveprompt'].includes(story.group)
                  )
                )
              ) {
                this.cancelAddBranch();
              } else {
                this.setState({
                  ConfirmationCancelAddBranch: true,
                });
              }
            }}
            update={this.handleAnyBranchUpdate}
            onBranchUpdate={this.handleBranchUpdate}
            onBranchCreate={this.handleBranchCreate}
            disabled={
              !modeEdit
              // eslint-disable-next-line max-len
              || PremiumIpDisabledEdit(authUser.role, story.book, story.group)
              || PremiumIpDisabledApprovedEdit(authUser.role, story.book)
              || isLiveEditDisabled(authUser.role, story.group)
            }
            onLocationSelected={this.onLocationSelected}
            lastLocation={lastLocation}
            limits={limits}
            onError={this.handleOnError}
            restrictedEdit={['live', 'liveprompt'].includes(story.group)}
            memoryBank={memories}
          />

          <CancelAddBranch
            show={ConfirmationCancelAddBranch}
            onHide={() => {
              this.setState({
                ConfirmationCancelAddBranch: false,
              });
            }}
            update={this.cancelAddBranch}
          />

        </>
      );
    }
    return false;
  };

  closeLocations = () => {
    const { newStories, story } = this.state;
    const { match, context } = this.props;
    const { bookLocationContext } = context;

    if (story.book?.id) {
      bookLocationContext.fetchBookLocations(story.book.id);
    }
    this.loadBranchList();
    this.updatePreviewAllBranches();

    this.setState({ showLocations: false });

    if (newStories === match.params.id) {
      sessionStorage.setItem('newStories', JSON.stringify({
        id: newStories,
        step: 2,
      }));
      this.newStorySteps();
    }
  };

  listLocations = () => {
    const {
      authUser,
      story,
      limits,
      modeEdit,
      showLocations,
    } = this.state;

    if (showLocations === true) {
      return (
        <Locations
          user={authUser}
          book={story.book}
          bookId={story.book.id}
          bookTitle={story.book.title}
          show={showLocations}
          onHide={this.closeLocations}
          disabled={!modeEdit}
          limits={limits}
          disabledEdit={
            !modeEdit
            // eslint-disable-next-line max-len
            || PremiumIpDisabledEdit(authUser.role, story.book, story.group)
            || PremiumIpDisabledApprovedEdit(authUser.role, story.book)
            || isLiveEditDisabled(authUser.role, story.group)
          }
        />
      );
    }
    return false;
  };

  closeStoryNotFound = () => {
    window.location.assign('/stories');
  };

  storyNotFound = () => {
    const { notFound } = this.state;
    if (notFound !== true) {
      return null;
    }
    return (
      <NotFoundModal
        show={notFound}
        onHide={this.closeStoryNotFound}
      />
    );
  };

  editBookTags = () => {
    const {
      authUser,
      story,
      showEditBookTags,
      modeEdit,
    } = this.state;

    if (showEditBookTags === true) {
      return (
        <BookTagsModal
          book={story.book}
          show={showEditBookTags}
          disabled={
            !modeEdit
            || PremiumIpDisabledEdit(authUser.role, story.book, story.group)
            || PremiumIpDisabledApprovedEdit(authUser.role, story.book)
            || isLiveEditDisabled(authUser.role, story.group)
          }
          onHide={() => {
            this.setState({ showEditBookTags: false });
          }}
          update={() => {
            this.setState({ showEditBookTags: false });
          }}
        />
      );
    }
    return false;
  };

  renderBookCover = () => {
    const {
      showBookCover,
      story,
      authUser,
    } = this.state;

    if (showBookCover) {
      return (
        <BookCover
          user={authUser}
          book={story.book}
          show={showBookCover}
          onHide={this.actionBookCover}
          update={() => {
            this.loadSettings();
            this.loadBranchList();
            this.loadStory();
            this.newStorySteps();
            this.actionBookCover();
          }}
          disabled={(
            !(story && story.book)
            || (
              authUser.role !== 'admin'
              && story.book.book_role !== 'owner'
              && story.book.book_role !== 'editor'
              && story.book.book_role !== 'co-author'
            )
          )}
        />
      );
    }
    return null;
  };

  renderChapterRestore = () => {
    const {
      story,
      showChapterRestore,
    } = this.state;

    if (!showChapterRestore) {
      return null;
    }

    return (
      <ChapterRestoreModal
        book={story.book}
        obj={story}
        show
        update={this.loadBranchList}
        onHide={() => {
          this.setState({ showChapterRestore: false });
        }}
      />
    );
  };

  storyBranchesPage = () => {
    const {
      authUser,
      keyDownFlag,
      branch,
      snapToGrid,
      branchesListPanel,
      addBranchActive,
      branchesScale,
      story,
      validatePanel: validatePanel1,
      GoLiveLoading,
      limits: propsLimits,
      scrollToNode,
      showGrid,
      preview,
      infoPanel,
      previewPanel: propsPreviewPanel,
      modeEdit,
      savePositionLoading,
    } = this.state;

    const { match } = this.props;

    const validatePanel = infoPanel || branchesListPanel || validatePanel1 ? 'main-panel-sm' : null;
    const previewPanel = propsPreviewPanel ? 'preview-panel' : null;
    const REACT_APP_LIVE = process.env.REACT_APP_LIVE !== undefined && process.env.REACT_APP_LIVE && process.env.REACT_APP_LIVE === 'true';
    const limits = propsLimits && Object.entries(propsLimits).length > 0 ? propsLimits : undefined;

    return {
      header: {
        title: story.title ? `Episode: ${story.title}` : `Episode # ${match.params.id}`,
        type: 'branches',
        settings: (authUser.role === 'admin') && 'branches',
        settingsItems: (authUser.role === 'admin') && [
          {
            title: 'Node Positioning',
            disabled: !modeEdit,
            action: () => {
              this.setState({
                NodePositioningModal: true,
              });
            },
          },
        ],
        contentRef: this.mainContent,
        mainContentStyle: keyDownFlag.Shift ? 'content-branches-shift content-branches' : 'content-branches',
        mainContentPanel: ` ${validatePanel} ${previewPanel} `,
      },
      sidebar: {
        nav: [
          {
            title: 'Back to book',
            href: `/book/${story.bookId}`,
            variant: 'secondary',
            disabled: !story.bookId,
          },
          {
            title: !preview ? 'Play in emulator' : 'Close emulator',
            action: () => this.setState((state) => ({ preview: !state.preview, previewPanel: !state.previewPanel })),
            variant: 'success',
            disabled: branch.length < 1,
            activeWizardStep: 10,
          },
          {
            title: 'Share emulator link',
            variant: 'success',
            action: () => {
              this.setState({
                shareModal: true,
              });
            },
            disabled: story && story.book && story.book.original,
          },
          REACT_APP_LIVE
          && {
            title: this.livePromptTitle(),
            action: () => {
              this.setState({
                GoLiveModal: true,
              });
            },
            loading: GoLiveLoading,
            variant: story.group === 'active' || story.group === 'prompt' ? 'success' : 'secondary',
            disabled:
              !(story.group === 'active' || story.group === 'prompt')
              || GoLiveLoading
              || (authUser.role !== 'admin' && ['viewer', 'editor'].includes(story.story_role))
              || !modeEdit
              || PremiumIpDisabledEdit(authUser.role, story.book, story.group)
              || PremiumIpDisabledApprovedEdit(authUser.role, story.book)
              || isLiveEditDisabled(authUser.role, story.group)
            ,
          },
          {
            title: 'Create Node',
            variant: 'CreateNodeButton',
            branchesScale,
            addBranchActive,
            modeEdit: !modeEdit
              || PremiumIpDisabledEdit(authUser.role, story.book, story.group)
              || PremiumIpDisabledApprovedEdit(authUser.role, story.book)
              || isLiveEditDisabled(authUser.role, story.group),
            action: this.addBranches,
          },
          {
            title: 'Search',
            action: () => {
              this.setState((prevState) => ({
                branchesListPanel: !prevState.branchesListPanel,
              }));
            },
            variant: 'primary',
          },
          {
            title: 'Characters',
            action: () => this.actionEditCharacters(),
            variant: 'primary',
            disabled: false,
          },
          {
            title: 'Locations',
            action: () => this.initLocations(),
            variant: 'primary',
            disabled: false,
            activeWizardStep: 8,
          },
          {
            title: 'Memory Bank',
            action: () => this.setState({ showMemoryBank: true }),
            variant: 'primary',
            disabled: false,
            NotAdminPermissions: !isMemoryBankEnabled(),
          },
          {
            title: 'Achievements',
            action: () => this.setState({ showAchievementsModal: true }),
            variant: 'primary',
            disabled: false,
            NotAdminPermissions: !(isMemoryBankEnabled() && isAchievementsEnabled()),
          },
          {
            title: 'Episode Goals',
            action: () => this.setState({ showPerformanceRanksModal: true }),
            variant: 'primary',
            disabled: false,
            activeWizardStep: 8,
            NotAdminPermissions: !isMemoryBankEnabled(authUser),
          },
          {
            variant: 'space',
          },
          {
            title: this.validateBadge(),
            action: () => {
              this.setState((prevState) => ({
                validatePanel: !prevState.validatePanel,
              }));
            },
            variant: 'primary',
            disabled: false,
          },
          {
            title: 'Edit Story Tags',
            action: () => this.setState({ showEditBookTags: true }),
            variant: 'primary',
            disabled:
              authUser.role !== 'admin'
              && !(
                story.book
                && ['owner', 'editor', 'co-author'].includes(story.book.book_role))
            ,
          },
          {
            title: 'Options',
            variant: 'Options',
            ref: this.optionsRef,
            id: this.props.match.params.id,
            user: authUser,
            update: () => this.loadStory(),
            limits,
            modeEdit: modeEdit
              || PremiumIpDisabledEdit(authUser.role, story.book, story.group)
              || PremiumIpDisabledApprovedEdit(authUser.role, story.book)
              || isLiveEditDisabled(authUser.role, story.group),
          },
          {
            variant: 'space',
          },
          {
            title: 'Snap to Grid',
            action: () => {
              this.setState((state) => ({ snapToGrid: !state.snapToGrid }));
            },
            variant: 'snapToGridCheckbox',
            id: 'snapToGrid',
            disabled: !modeEdit,
            value: snapToGrid,
          },

          {
            title: 'Show grid',
            action: () => {
              this.setState((state) => ({ showGrid: !state.showGrid }));
            },
            variant: 'showGridCheckBox',
            id: 'showGrid',
            disabled: !modeEdit,
            value: showGrid,
          },

          {
            title: 'Scroll to node',
            action: () => {
              this.setState((state) => ({ scrollToNode: !state.scrollToNode }));
            },
            variant: 'showGridCheckBox',
            id: 'scrollToNode',
            value: scrollToNode,
          },
          {
            action: (newScale) => {
              const { scaleFactor, adjustedValue } = this.calculateScrollFactor(branchesScale, newScale);
              this.setState({ branchesScale: newScale });
              const obj = this.mainContent.current;
              obj.scrollTo((obj.scrollLeft * scaleFactor) * adjustedValue, (obj.scrollTop * scaleFactor) * adjustedValue);
            },
            variant: 'zoom',
            branchesScale,
          },
          {
            title: 'See Whole Episode',
            action: () => {
              const branches = branch;
              const maxX = branches.reduce((prev, curr) => (prev > curr.x ? prev : curr.x));
              const minX = branches.reduce((prev, curr) => (prev < curr.x ? prev : curr.x));
              const maxY = branches.reduce((prev, curr) => (prev > curr.y ? prev : curr.y));
              const minY = branches.reduce((prev, curr) => (prev < curr.y ? prev : curr.y));
              const box = this.mainContent.current.getBoundingClientRect();
              const val = Math.min(1, box.width / (maxX + minX + 200), box.height / (maxY + minY + 200));

              this.setState({
                branchesScale: val,
              });
              const obj = this.mainContent.current;
              obj.scrollTo(0, 0);
            },
            variant: 'primary',
            disabled: authUser.role !== 'admin' && story.story_role !== 'owner',
          },
          {
            title: 'Restore this version',
            action: () => this.actionChapterRestore(),
            variant: 'primary',
            disabled:
              (
                authUser.role !== 'admin'
                && story.story_role !== 'owner'
              )
              || !modeEdit
              || PremiumIpDisabledEdit(authUser.role, story.book, story.group)
              || PremiumIpDisabledApprovedEdit(authUser.role, story.book)
              || isLiveEditDisabled(authUser.role, story.group),
            NotAdminPermissions: !(story && ['submitted', 'rejected', 'approved', 'pending', 'live', 'liveprompt', 'prompt'].includes(story.group)),
          },
          { variant: 'space' },
          {
            title: 'Info',
            action: () => {
              this.setState((prevState) => ({
                infoPanel: !prevState.infoPanel,
              }));
            },
            variant: 'primary',
            disabled: false,
          },
          {
            title: this.importAsTextBadge(),
            action: () => this.setState({ showImportTextModal: true }),
            variant: 'primary',
            disabled: savePositionLoading || !modeEdit,
          },
          {
            title: 'Export',
            action: () => Export.exportStory(this.props.match.params.id, story.title),
            variant: 'primary',
            disabled: authUser.role !== 'admin' && story.story_role !== 'owner',
            NotAdminPermissions: !authUser || authUser.role !== 'admin',
          },
          {
            title: 'Help',
            action: () => {
              this.setState((prevState) => ({
                HelpPanel: !prevState.HelpPanel,
              }));
            },
            variant: 'primary',
          },
        ],
      },
    };
  };

  handleEditStep = (branchId, stepId) => {
    const { branch: branches } = this.state;
    if (!branchId || !stepId) {
      return;
    }
    const branch = branches.find((b) => b.id === branchId);
    this.initAddBranch({ ...branch, editStepId: stepId });
  };

  render() {
    const {
      isAnalyticsVisible,
      storePublicInterface,
      story,
      authUser,
      redirect,
      selectionItems,
      keyDownFlag,
      isDuplicateMode,
      savePositionLoading,
      branchesScale,
      sourceNodeId,
      destinationNodeId,
      branchOnNameEdit,
      showMemoryBank,
      showAchievementsModal,
      showImportTextModal,
      branch: nodes,
      reFetchMemoriesTrigger,
      showPerformanceRanksModal,
    } = this.state;

    if (redirect) {
      return (<Redirect to={redirect} />);
    }

    if (!story.book) {
      return null;
    }

    const { context } = this.props;
    const { memoryBankContext, memoryIconsContext } = context;

    const linkUrl = document.location.origin.replace('www.', '');
    const bookStyle = this.state.story.book && this.state.story.book.bookStyle;
    const bookStyleAlias = bookStyle && bookStyle.alias === 'arcana_style' ? 'arcana' : undefined;

    const isAdmin = authUser?.role === 'admin';
    const isSendNotifications = story?.book?.sendNotificationsFor?.includes(SendNotificationsFor.Achievement) ?? false;

    return (
      <AchievementsContextProvider
        bookId={story?.book?.id}
        isSendNotifications={isAdmin && isSendNotifications}
      >
        <PageWrapper
          {...this.props}
          page={this.storyBranchesPage()}
        >
          <div
            className={cs(`content-branches-wrapper branchesScale_${branchesScale * 100}`)}
            style={{
              transform: `scale(${branchesScale})`,
              width: `${this.state.branchesWrapper.x}px`,
              height: `${this.state.branchesWrapper.y}px`,
            }}
            ref={this.mainContentWrapper}
          >

            <div className={cs(`gridBg ${this.state.showGrid ? 'gridBgShow' : ''}`)} />

            <div
              className={cs([
                'wrapItems',
                this.state.sourceNodeId ? 'activeConnection' : '',
                this.state.sourceNodeIdType === 'choice' ? 'activeConnectionChoice' : '',
                this.state.dragStart || keyDownFlag.Shift || branchOnNameEdit ? 'SelectableGroupDisabled' : null])}
            >

              <SelectableGroup
                ref={this.selectionRef}
                className="wrapItems wrapItems-selection"
                enableDeselect={keyDownFlag.Control || keyDownFlag.Meta}
                mixedDeselect={keyDownFlag.Control || keyDownFlag.Meta}
                resetOnStart={false}
                globalMouse={false}
                allowClickWithoutSelected={false}
                onSelectionFinish={this.handleSelectionFinish}
                ignoreList={['.not-selectable', '.DeselectAll-button']}
                disabled={this.state.dragStart
                  || keyDownFlag.Shift
                  || branchOnNameEdit
                  || ['live', 'liveprompt'].includes(this.state.story.group)
                  || !!sourceNodeId}
                delta={branchesScale}
                allowCtrlClick
              >
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                  ref={this.scrollRef}
                  className={`content-dbClickArea ${isDuplicateMode ? ' cursor-point' : ''}`}
                  // // Used mouseUp instead onClick because SelectableGroup prevent onClick event
                  onMouseDown={(e) => {
                    if (!isDuplicateMode
                      && !(keyDownFlag.Control || keyDownFlag.Meta || keyDownFlag.Shift || keyDownFlag.Alt)
                      && !sourceNodeId
                    ) {
                      this.handleSelectionClear();
                    }
                    if (isDuplicateMode && this.state.modeEdit) {
                      this.handleDoubleClickItem(e);
                    }

                    if (sourceNodeId || destinationNodeId) {
                      this.setState({
                        sourceNodeId: null,
                        destinationNodeId: null,
                      });
                    }
                  }}
                  onDoubleClick={(e) => {
                    if (!isDuplicateMode && this.state.modeEdit) {
                      this.handleDoubleClickItem(e);
                    }
                  }}
                />
                {this.branchesItems()}
              </SelectableGroup>
            </div>

            {this.branchesDragItem()}

            <div className={cs('wrapLines')}>
              {this.branchesLines()}
            </div>

            <div id="boxConnection" />

          </div>

          {this.state.savePositionLoading && (
          <Spinner
            animation="border"
            variant="primary"
            className="story-branches-spinner justify-content-center"
          />
          )}

          <div
            className={`modeAlert ${this.state.modeAdminStyle}`}
          >
            <Alert
              className={`${this.state.modeEditStyle}`}
              variant={!this.state.modeEdit ? 'info' : 'danger'}
            >
              <Form.Check
                custom
                type="checkbox"
                id="NodeEditMode"
                label={
                  !this.state.modeEdit
                    ? 'Node Read-Only Mode'
                    : 'Node Edit Mode'
                }
                checked={this.state.modeEdit}
                onChange={() => {
                  this.setState((prevState) => {
                    const modeEdit = !prevState.modeEdit;
                    localStorage.setItem(`modeEdit-${prevState.story.internal_uuid}`, !modeEdit);
                    localStorage.setItem('modeEdit', !modeEdit);
                    return {
                      modeEdit,
                    };
                  });
                }}
              />
            </Alert>
          </div>

          {this.state.NodePositioningModal
          && (
          <NodePositioningModal
            show={this.state.NodePositioningModal}
            branch={this.state.branch}
            storyId={this.props.match.params.id}
            onHide={() => {
              this.setState({
                NodePositioningModal: false,
              });
            }}
            update={this.loadBranchList}
          />
          )}
          <DetachablePreview
            show={this.state.preview}
            update={this.handlePreviewUpdate}
            storyUuid={story.internal_uuid}
            bookStyle={bookStyleAlias}
            onClose={this.previewClose}
            store={storePublicInterface?.internalStore}
            onPreviewExit={handlePreviewExit}
            displayAnalyticsOption={isAnalyticsVisible ? 'percentage' : 'hide'}
            isBranchDetailsCacheDisabled
            showAnalyticsPanel={isAnalyticsVisible}
            bookId={story.bookId}
            scrollToBranch={this.scrollToBranch}
            onEditStep={this.handleEditStep}
            reFetchMemoriesTrigger={reFetchMemoriesTrigger}
          />
          {
            isDuplicateMode
            && (
            <Alert
              className="duplicateBranchsAlert"
              variant="dark"
            >
              <img src={IconCursor} alt="" />
              click to paste
            </Alert>
            )
          }

          <HelpSection
            onDelete={this.handleBranchDelete}
            show={this.state.HelpPanel}
            onClose={() => this.setState({ HelpPanel: false })}
            user={authUser}
            onDuplicate={this.duplicateBranchs}
            storyId={this.props.match.params.id}
            selectedBranches={this.state.branch.filter((branch) => selectionItems.includes(branch.id))}
            onSharedSettingsUpdate={this.sharedSettingsUpdate}
            disabled={(
              isDuplicateMode
                || savePositionLoading
                || (authUser.role !== 'admin' && this.state.story.story_role === 'viewer')
                || !this.state.modeEdit
                || PremiumIpDisabledEdit(authUser.role, this.state.story.book, this.state.story.group)
                || PremiumIpDisabledApprovedEdit(authUser.role, this.state.story.book)
              )}
          />
        </PageWrapper>

        {this.addBranch()}
        {this.listLocations()}
        {this.storyNotFound()}
        {this.editBookTags()}

        <SharedSettingsPanel
          user={authUser}
          storyId={this.props.match.params.id}
          story={this.state.story}
          selectedItems={selectionItems}
          show={this.state.sharedSettingsPanel}
          onHide={this.sharedSettingsPanelHide}
          update={this.loadBranchList}
          limits={this.state.limits}
        />

        <ValidatePanel
          lastEdit={this.state.lastEdit}
          branch={this.state.branch}
          storyId={this.props.match.params.id}
          data={this.state.editBranchData}
          stepTypes={this.state.stepTypes}
          show={this.state.validatePanel}
          onHide={this.validatePanelHide}
          validateAction={this.validateAction}
          errCount={this.errCount}
        />

        <InfoPanel
          lastEdit={this.state.lastEdit}
          branch={this.state.branch}
          story={this.state.story}
          storyId={this.props.match.params.id}
          data={this.state.editBranchData}
          stepTypes={this.state.stepTypes}
          show={this.state.infoPanel}
          onHide={this.infoPanelHide}
          validateAction={this.validateAction}
          isAnalyticsVisible={this.state.isAnalyticsVisible}
        />

        <BranchesListPanel
          lastEdit={this.state.lastEdit}
          branch={this.state.branch}
          story={this.state.story}
          storyId={this.props.match.params.id}
          data={this.state.editBranchData}
          stepTypes={this.state.stepTypes}
          show={this.state.branchesListPanel}
          onHide={this.branchesListPanelHide}
          validateAction={this.validateAction}
          actionEditBranch={this.initAddBranch}
          showAnalytics={this.state.isAnalyticsVisible}
        />

        {this.characters()}

        {this.editCover()}

        {this.state.story?.book && (
        <PublishModal
          show={this.state.GoLiveModal}
          book={this.state.story.book}
          title={this.livePromptTitle()}
          isLoading={this.state.GoLiveLoading}
          publishTypeValue={this.state.story.book.exclusiveAccess ? PublishType.Exclusive : PublishType.General}
          onConfirm={() => this.updateGoLive()}
          onCancel={() => this.setState({ GoLiveModal: false })}
          willAlertShowForNewUser={this.state.story
                    && !(this.state.story.book && !!this.state.story.book.original)
                    && this.state.story.live_count === 0
                    && !authUser.firstPublishData}
        />
        )}

        <ShareEmulatorLinkModal
          show={this.state.shareModal}
          onHide={() => this.setState({ shareModal: false })}
          linkUrl={linkUrl}
          storyInternalUuid={this.state.story.internal_uuid}
        />

        <SaveAsTemplate
          limits={this.state.limits}
          show={this.state.saveTemplateModal}
          story={this.state.story}
          onSuccess={() => {
            this.loadStory();
          }}
          onValidationError={() => {
            this.setState({
              saveTemplateModal: false,
              validatePanel: true,
            });
          }}
          onHide={() => {
            this.setState({
              saveTemplateModal: false,
            });
            this.loadStory();
          }}
        />

        {this.renderBookCover()}
        {this.renderChapterRestore()}

        {showMemoryBank && (
          <MemoryBankModal
            bookId={story.book.id}
            bookUsers={story.book.users}
            onHide={() => {
              this.setState({
                showMemoryBank: false,
                reFetchMemoriesTrigger: reFetchMemoriesTrigger + 1,
              });
              memoryBankContext.fetchMemories();
              memoryIconsContext.fetchMemoryIcons();
            }}
          />
        )}

        {showImportTextModal && (
          <ImportTextModal
            bookId={story.book.id}
            episodeId={story.id}
            onCancel={() => {
              this.setState({ showImportTextModal: false });
            }}
            onSuccess={(nodeId) => {
              this.setState({ lastEdit: nodeId, showImportTextModal: false });
              this.updatePreviewAllBranches();
              this.loadBranchList().then(() => {
                this.scrollToBranch(nodeId, true);
              });
            }}
            nodes={nodes}
          />
        )}

        {showPerformanceRanksModal && (
        <PerformanceRanksContextProvider episodeId={story?.id} episodeTitle={`Episode ${story?.chapter ?? '-'}`}>
          <PerformanceRanksModal
            onHide={() => this.setState({
              showPerformanceRanksModal: false,
            })}
            disabled={isLiveEditDisabled(authUser.role, story.group)}
          />
        </PerformanceRanksContextProvider>
        )}

        {showAchievementsModal && (
        <AchievementsModal
          onHide={() => {
            this.setState({
              showAchievementsModal: false,
            });
          }}
        />
        )}
      </AchievementsContextProvider>
    );
  }
}
