import { useEffect, useRef, useState } from 'react';

export function useVideoPlayer(videoURL, videoSettings) {
  const videoPlayer = useRef(document.createElement('video'));

  const [videoVolume, setVideoVolume] = useState(videoSettings?.volume ?? 1);
  const [isLoopVideo, setIsLoopVideo] = useState(videoSettings?.loop ?? 0);
  const [allowStepActions, setAllowStepActions] = useState(videoSettings?.allowStepActions ?? 0);

  const [isPlayingVideo, setIsPlayingVideo] = useState(false);

  const togglePlayVideo = () => setIsPlayingVideo((oldValue) => !oldValue);

  useEffect(() => {
    videoPlayer.current.volume = videoVolume;
  }, [videoVolume]);

  useEffect(() => {
    const player = videoPlayer.current;
    player.pause();
    if (isPlayingVideo && videoURL) {
      player.src = videoURL;
      player.play();
      player.onended = togglePlayVideo;
    }
    return () => {
      player.pause();
    };
  }, [isPlayingVideo, videoURL]);

  useEffect(() => {
    videoPlayer.current.loop = isLoopVideo;
  }, [isLoopVideo]);

  return {
    videoPlayer, // Reference to the video element
    videoVolume,
    setVideoVolume,
    isLoopVideo,
    setIsLoopVideo,
    allowStepActions,
    setAllowStepActions,
    isPlayingVideo,
    togglePlayVideo,
  };
}
