import cn from 'classnames';
import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { listColumnsSize } from '../VideosBasicWithPick/constants';
import classNames from './VideoBasicWithPick.module.scss';

export function VideoBasicWithPick({
  video,
  isSelected,
  onClick,
}) {
  const handleClick = () => onClick(video);

  return (
    <Row
      className={cn(classNames.item, { [classNames.selected]: isSelected })}
      onClick={handleClick}
    >
      <Col sm={listColumnsSize.title}>
        <Col className={classNames.fieldTitle}>
          {video.title}
        </Col>
        <Col className={classNames.fieldAlias}>
          {video.alias}
        </Col>
      </Col>
    </Row>
  );
}
