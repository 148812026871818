import { omit } from 'lodash-es';
import React from 'react';
import { Form } from 'react-bootstrap';
import { MemoryType, MemoryTypeLabel } from '../memoryBankTypes';
import classes from './MemoryField.module.scss';

export function MemoryTypeField(props: React.ComponentProps<typeof Form.Control>) {
  const {
    id,
    value = 'string',
    ...rest
  } = props;

  // https://dorian.atlassian.net/browse/DOR-7910 "Role" variable type is accessible in the engine
  const memoryTypeFiltered = value !== MemoryType.Character
    ? omit(MemoryType, 'Character')
    : MemoryType;

  return (
    <Form.Group>
      <Form.Label
        htmlFor={id}
        className={classes.memoryLabel}
      >
        Type
      </Form.Label>
      <Form.Control
        id={id}
        as="select"
        value={value}
        {...rest}
      >
        {Object.entries(memoryTypeFiltered).map(([memoryTypeKey, memoryTypeValue]) => (
          <option key={memoryTypeKey} value={memoryTypeValue}>
            {MemoryTypeLabel[memoryTypeValue] ?? memoryTypeValue}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
}
