import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import cs from 'classnames';
import React from 'react';
import { AchievementRow, AchievementRowProps } from '../AchievementRow/AchievementRow';

export function AchievementRowSortable(props: AchievementRowProps & { id: number | string }) {
  const { id, ...rest } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    transition: {
      duration: 500,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  });

  const style = {
    backgroundColor: 'white',
    transform: CSS.Transform.toString(transform),
    borderBottom: '2px dotted black',
    marginBottom: '1rem',
    transition,
    boxShadow: isDragging
      ? '-1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25)'
      : undefined,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={cs('d-flex position-relative', isDragging && 'sort-item')}
    >
      <span
        aria-readonly
        style={{
          cursor: 'grab', touchAction: 'none', width: '100%', maxWidth: '20px',
        }}
        {...attributes}
        {...listeners}
      >
        :::
      </span>
      <AchievementRow
        {...rest}
      />
    </div>
  );
}
