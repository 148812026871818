import { useContext } from 'react';
import * as React from 'react';
import { Spinner } from 'react-bootstrap';
import { DebouncedSearch } from '../../../ui/DebouncedSearch/DebouncedSearch';
import { Pagination } from '../../../ui/Pagination/Pagination';
import classNames from './VideoLibraryContainer.module.scss';
import { Videos } from './Videos/Videos';
import { VideosContext, useRefetchVideo } from './VideosProvider/VideosProvider';
import { pageSize } from './VideosProvider/useVideos';

export function VideoLibraryContainer() {
  const {
    currentPage,
    setCurrentPage,
    totalCount,

    videos,
    isLoading,

    handleSearchSubmit,
    searchTerm,
  } = useContext(VideosContext);

  const refetchVideo = useRefetchVideo();

  const handleVideosOnChange = () => {
    const lastPage = Math.ceil(videos.length / pageSize);
    if (lastPage < currentPage) {
      setCurrentPage(lastPage);
    }
    refetchVideo();
  };

  return (
    <>
      {isLoading && (
        <Spinner
          className={classNames.spinner}
          variant="primary"
          animation="border"
          size="lg"
        />
      )}
      <DebouncedSearch
        initialValue={searchTerm}
        onSearchSubmit={handleSearchSubmit}
      />
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={totalCount}
        pageSize={pageSize}
        onPageChange={setCurrentPage}
      />
      <Videos
        videos={videos}
        onChange={handleVideosOnChange}
      />
    </>
  );
}
