import moment from 'moment';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import imgDelete from '../../../../../assets/images/delete.svg';
import { listColumnsSize } from '../Videos/constants';
import { DeleteVideoModal } from '../DeleteVideo/DeleteVideoModal';
import classNames from './Video.module.scss';

export function Video({
  video,
  onChange,
}) {
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);

  const handleVideoDelete = () => {
    setIsDeleteModalShown(false);
    onChange();
  };

  const handleVideoCancel = () => {
    setIsDeleteModalShown(false);
  };

  const handleDeleteClick = () => setIsDeleteModalShown(true);

  return (
    <>
      <Row className={classNames.item}>
        <Col sm={listColumnsSize.title}>
          <Col className={classNames.fieldTitle}>
            {video.title}
          </Col>
          <Col className={classNames.fieldAlias}>
            {video.alias}
          </Col>
        </Col>
        <Col sm={listColumnsSize.actions} className={classNames.fieldActions}>
          <Button
            onClick={handleDeleteClick}
            variant="secondary"
            size="sm"
            className="mx-1"
          >
            <img src={imgDelete} className="btnImg" alt="Delete" />
          </Button>
        </Col>
      </Row>
      { isDeleteModalShown && (
        <DeleteVideoModal
          video={video}
          onDelete={handleVideoDelete}
          onCancel={handleVideoCancel}
        />
      )}
    </>
  );
}
