import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { api } from '../../../../api';

export const pageSize = 10;

export function useVideos() {
  const [searchTerm, setSearchTerm] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [videos, setVideos] = useState([]);

  const refetchVideo = useCallback(
    (cancelToken) => {
      const offset = pageSize * (currentPage - 1);
      const params = {
        limit: pageSize,
        offset,
        search: searchTerm || undefined,
        order: 'createdAt:desc',
      };

      setIsLoading(true);

      api.get('/v1/video', {
        params,
        cancelToken: cancelToken ? cancelToken.token : undefined,
      })
        .then((res) => {
          const { videos: videosData } = res.data;
          setTotalCount(res.data.totalCount);
          setVideos(videosData);
        })
        .finally(() => setIsLoading(false));
    },
    [currentPage, searchTerm, setTotalCount],
  );

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    refetchVideo(cancelToken);
    return () => {
      cancelToken.cancel('VideoLibrary unmount');
    };
  }, [refetchVideo]);

  const handleSearchSubmit = (newSearchTerm) => {
    setCurrentPage(1);
    setSearchTerm(newSearchTerm);
  };

  return {
    videos,
    isLoading,
    refetchVideo,
    currentPage,
    setCurrentPage,
    totalCount,
    handleSearchSubmit,
    searchTerm,
  };
}
