import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { VideoBasicWithPick } from '../VideoBasicWithPick/VideoBasicWithPick';
import classNames from './VideosBasicWithPick.module.scss';
import { listColumnsSize } from './constants';

export function VideosBasicWithPick({
  videos,
  selectedId,
  onClick,
}) {
  return (
    <>
      <Row className={classNames.listTile}>
        <Col sm={listColumnsSize.title} className={classNames.listTileColumn}>
          Title / Alias
        </Col>
      </Row>
      <ul className={classNames.list}>
        {
        videos.map((video) => (
          <li
            key={video.id}
            className={classNames.listItem}
          >
            <VideoBasicWithPick
              key={video.id}
              video={video}
              isSelected={video.id === selectedId}
              onClick={onClick}
            />
          </li>
        ))
      }
      </ul>
    </>
  );
}
