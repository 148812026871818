import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Video } from '../Video/Video';
import classNames from './Videos.module.scss';
import { listColumnsSize } from './constants';

export function Videos({
  videos,
  onChange,
}) {
  return (
    <>
      <Row className={classNames.listTile}>
        <Col sm={listColumnsSize.title} className={classNames.listTileColumn}>
          Title / Alias
        </Col>
        <Col sm={listColumnsSize.actions} className={classNames.listTileColumn}>
          Actions
        </Col>
      </Row>
      <ul className={classNames.list}>
        {
        videos.map((video) => (
          <li
            key={video.id}
            className={classNames.listItem}
          >
            <Video
              key={video.id}
              video={video}
              onChange={onChange}
            />
          </li>
        ))
      }
      </ul>
    </>
  );
}
