import { useCallback, useEffect, useState } from 'react';
import { Story } from '../../../../dorian-shared/types/story/Story';
import { api } from '../../../api';

export function useEpisodes(bookId: number | undefined) {

  const [episodes, setEpisodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchEpisodes = useCallback(() => {
    const abortController = new AbortController();
    setIsLoading(true);
    
    api.get(`v1/books/${bookId}/chapters/?groupId=1,9&offset=0&limit=100&latest=1&order=updatedAt:asc`, {
      signal: abortController.signal,
    })
      .then((response) => {
        const sortedChapters = response.data.chapters.sort((a: Story, b: Story) => {
          const chapterA = a.chapter ?? 0;
          const chapterB = b.chapter ?? 0;
          return chapterA - chapterB;
        });
        setEpisodes(sortedChapters);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      abortController.abort();
    };
  }, [bookId]);

  useEffect(() => {
    fetchEpisodes();
  }, [fetchEpisodes]);
  return {
    episodes,
    isLoading,
  };
}
