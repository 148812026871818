import { v4 as uuidV4 } from 'uuid';
import { IAchievementGetDTO, IAchievementPostDTO, INotification } from 'dorian-shared/types/achievement/Achievement';
import { StepCheckOperatorType } from '../Book/MemoryBank/memoryBankTypes';
import { IAchievementModal } from './AchievementsModalTypes';

const initialNotification: INotification = {
  title: '',
  message: '',
};

export const newAchievement = (id: number|string = uuidV4()): IAchievementModal => ({
  id,
  name: '',
  description: '',
  displayName: '',
  icon: '',
  isAdded: true,
  isManual: false,
  unavailableAfter: undefined,
  check: {
    variableId: 0,
    operator: StepCheckOperatorType.AtLeast,
    value: '',
  },
  usedInStory: [],
  isOwnedByUser: false,
  notification: initialNotification,
  reward: undefined,
});

export const convertAchievementToPostDTO = (achievement: IAchievementModal | IAchievementGetDTO): IAchievementPostDTO => {
  const { notification } = achievement;
  if (notification) {
    notification.title = notification.title?.trim() ?? '';
    notification.message = notification.message?.trim() ?? '';
  }

  const isRewardContentNone = achievement.reward?.contentId === ''
    || achievement.reward?.contentId === null
    || achievement.reward?.contentId === undefined;

  return {
    name: achievement.name,
    icon: achievement.icon,
    displayName: achievement.displayName,
    description: achievement.description,
    isManual: achievement.isManual,
    check: { ...achievement.check },
    unavailableAfter: achievement.unavailableAfter,
    notification,
    rewardContentId: isRewardContentNone
      ? null
      : achievement.reward?.contentId,
    rewardType: achievement.reward?.type,
  };
};



export const convertAchievementGetDTOToModal = (achievement: IAchievementGetDTO): IAchievementModal => {
  const { notification } = achievement;
  if (notification) {
    notification.title = notification.title?.trim() ?? '';
    notification.message = notification.message?.trim() ?? '';
  }

  return {
    id: achievement.id,
    name: achievement.name,
    icon: achievement.icon,
    displayName: achievement.displayName,
    description: achievement.description,
    isManual: achievement.isManual,
    check: {
      ...achievement.check,
      value: Number.isNaN(achievement.check.value) ? 0 : Number(achievement.check.value),
    },
    isAdded: false,
    usedInStory: achievement.usedInStory,
    isOwnedByUser: achievement.isOwnedByUser,
    unavailableAfter: achievement.unavailableAfter,
    notification,
    reward: achievement.reward
      ? { ...achievement.reward }
      : { contentId: '' },
  };
};
