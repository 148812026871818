import { isArray } from 'lodash-es';
import React, {
  createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { showToast } from '../../components/ui/utils';
import { IAchievementGetDTO, IAchievementPostDTO } from '../../dorian-shared/types/achievement/Achievement';
import { useApiService } from '../ApiServiceContext/ApiServiceContext';

type AchievementsContextType = {
  isLoading: boolean,
  setIsLoading: (value: boolean) => void,
  achievements: IAchievementGetDTO[],
  fetchAchievements: (abortController?: AbortController) => Promise<IAchievementGetDTO[]>,
  createAchievement: (achievement: IAchievementPostDTO) => Promise<IAchievementGetDTO | null>,
  updateAchievement: (id: number | string, chievement: IAchievementPostDTO) => Promise<IAchievementGetDTO | null>,
  deleteAchievement: (id: number | string) => Promise<void>,
  checkUsedAchievementById: (id: number) => Promise<boolean>,
  updateOrder: (order: number[]) => Promise<void>,
  bookId: number | undefined,
  isSendNotifications: boolean,
};

const initialContext: AchievementsContextType = {
  isLoading: false,
  setIsLoading: () => null,
  achievements: [],
  fetchAchievements: () => Promise.resolve([]),
  createAchievement: () => Promise.resolve(null),
  updateAchievement: () => Promise.resolve(null),
  deleteAchievement: () => Promise.resolve(),
  checkUsedAchievementById: () => Promise.resolve(false),
  updateOrder: () => Promise.resolve(),
  bookId: undefined,
  isSendNotifications: false,
};

export const AchievementsContext = createContext<AchievementsContextType>(initialContext);

type AchievementsContextProviderProps = {
  children: ReactNode,
  bookId: number,
  isSendNotifications: boolean,
};

export function AchievementsContextProvider(props: AchievementsContextProviderProps) {
  const { children, bookId, isSendNotifications } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [achievements, setAchievements] = useState<IAchievementGetDTO[]>([]);

  const apiService = useApiService();

  const fetchAchievements = useCallback(async (abortController?: AbortController) => {
    const response = await apiService.fetchAchievementsByBookId(bookId, abortController);
    if (!isArray(response)) {
      throw new Error('Invalid  achievements response');
    }
    setAchievements(response);
    return response;
  }, [apiService, bookId]);

  const createAchievement = useCallback(async (achievement: IAchievementPostDTO) => {
    const response = await apiService.createAchievementByBookId(bookId, achievement);
    return response;
  }, [apiService, bookId]);

  const updateAchievement = useCallback(async (id: number | string, achievement: IAchievementPostDTO) => {
    const response = await apiService.updateAchievementByBookId(bookId, id, achievement);
    return response;
  }, [apiService, bookId]);

  const deleteAchievement = useCallback(async (id: number | string) => {
    await apiService.deleteAchievementByBookId(bookId, id);
  }, [apiService, bookId]);

  const checkUsedAchievementById = useCallback(async (id: number) => {
    const response = await apiService.checkUsedAchievementById(id);
    return response;
  }, [apiService]);

  const updateOrder = useCallback(async (order: number[]) => {
    await apiService.updateAchievementOrderByBookId(bookId, order);
  }, [apiService, bookId]);

  useEffect(() => {
    if (!bookId) {
      return () => null;
    }
    setIsLoading(true);
    const abortController = new AbortController();
    fetchAchievements(abortController)
      .catch(() => {
        showToast({ textMessage: 'Failed to fetch achievements' });
      })
      .finally(() => setIsLoading(false));
    return () => {
      abortController.abort();
    };
  }, [bookId, fetchAchievements]);

  const value = useMemo(() => ({
    isLoading,
    setIsLoading,
    achievements,
    fetchAchievements,
    createAchievement,
    updateAchievement,
    deleteAchievement,
    checkUsedAchievementById,
    updateOrder,
    bookId,
    isSendNotifications,
  }), [
    isLoading,
    achievements,
    fetchAchievements,
    createAchievement,
    updateAchievement,
    deleteAchievement,
    checkUsedAchievementById,
    updateOrder,
    bookId,
    isSendNotifications,
  ]);

  return (
    <AchievementsContext.Provider value={value}>
      {children}
    </AchievementsContext.Provider>
  );
}

export const useAchievementsContext = () => useContext(AchievementsContext);
