import async from 'async';
import serialize from 'form-serialize';
import React, { PureComponent } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Modal,
  Spinner,
} from 'react-bootstrap';
import { api } from '../../../api';
import { PremiumIpDisabledApprovedEdit } from '../../../utils/premiumIpDisabledApprovedEdit';
import { PremiumIpDisabledEdit } from '../../../utils/premiumIpDisabledEdit';
import { getAddStoryUserAccess } from '../../Stories/AddStoriesUtils';
import { recBonusContent, recChoices, recDecisionPoints } from '../constants';
import { BonusFormGroup } from './BonusFormGroup/RequiredAchievementFormGroup';
import { OptionAdminRequirements } from './OptionAdminRequirements';
import { OptionDisplaySetings } from './OptionDisplaySetings';

export class OptionPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      formError: null,
      title: '',
      uuid: '',
      summary: '',
      chapter: null,
      revision: 0,
      types: [],
      book: [],
      recommendations: {
        decisionPoints: recDecisionPoints,
        choices: recChoices,
        bonus_content: recBonusContent,
        stats: [],
      },
      loading: true,
      displaySettings: '',
      displaySettingsError: null,
      public: false,
      episodeErrorLimit: null,
      episodeSummaryErrorLimit: null,
      // eslint-disable-next-line react/no-unused-state
      author: '',
      // eslint-disable-next-line react/no-unused-state
      storyTags: [],
      // eslint-disable-next-line react/no-unused-state
      tags: null,
      isBonus: false,
    };
  }

  handleSubmit = (event) => {
    const { summary } = this.state;
    const { limits } = this.props;

    event.preventDefault();
    const formData = event.currentTarget;
    let validated = formData.checkValidity();

    if (summary.match(/^\s*$/)) {
      validated = false;
      this.setState({
        validated: false,
        summary: '',
      });
    }

    const storyToSave = serialize(formData, { hash: true });

    storyToSave.public = !!(storyToSave.public || storyToSave.public !== undefined);

    const isBonus = storyToSave.isBonus === 'on';
    storyToSave.isBonus = isBonus;
    storyToSave.chapter = isBonus ? null : parseInt(storyToSave.chapter, 10);

    if (storyToSave.title && storyToSave.title.length > Number(limits.story_title_max.value)) {
      validated = false;
      this.setState({ validated: false, episodeErrorLimit: true });
      event.stopPropagation();
    }

    if (storyToSave.summary && storyToSave.summary.length > Number(limits.story_summary_max.value)) {
      validated = false;
      this.setState({ validated: false, episodeSummaryErrorLimit: true });
      event.stopPropagation();
    }

    try {
      if (storyToSave.displaySettings) {
        storyToSave.displaySettings = JSON.parse(storyToSave.displaySettings);
      }
    } catch (e) {
      validated = false;
      this.setState({
        validated: false,
        displaySettingsError: e.message,
      });
    }

    if (validated === false) {
      event.stopPropagation();
    } else {
      this.addStories(storyToSave, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  };

  errorAlert = (error) => {
    this.setState({
      formError: error,
    });
    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 5000);
  };

  addStories(value, validated) {
    const { onHide, update, id } = this.props;
    if (validated === true) {
      this.setState({
        loading: true,
      });

      api.put(`/v1/stories/${id}`, value)
        .then((res) => {
          this.setState({
            validated: false,
            formError: null,
            pageTitle: res.data.story.title,
            title: res.data.story.title,
            // eslint-disable-next-line react/no-unused-state
            author: res.data.story.author,
            revision: res.data.story.revision,
            summary: res.data.story.summary,
            story_role: res.data.story.story_role,
            chapter: Number(res.data.story.chapter),
            public: res.data.story.public,
            loading: false,
          }, () => {
            onHide();
          });
          if (update) {
            update();
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          if (error.response) {
            this.errorAlert(error.response.data.error);
          }
        });
    }
  }

  componentDidUpdate(prevProps) {
    const { show } = this.props;

    if (prevProps.show !== show) {
      this.loadData();
    }
  }

  loadData() {
    const { id } = this.props;

    this.setState({
      loading: true,
    });
    async.parallel({
      stories: (callback) => {
        api.get(`/v1/stories/${id}`)
          .then((res) => {
            callback(null, res.data.story);
          }).catch((error) => {
            callback(error, null);
          });
      },
      types: (callback) => {
        api.get('/v1/stats/types')
          .then((res) => {
            callback(null, res.data.types);
          }).catch((error) => {
            callback(error, null);
          });
      },
      tags: (callback) => {
        api.get('/v1/tags')
          .then((res) => {
            callback(null, res.data.tags);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (err, res) => {
      try {
        if (err) {
          this.setState({
            loading: false,
          });
          if (err.response) {
            this.errorAlert(err.response.data.error);
          }
        } else {
          let displaySettings;
          if (res.stories.displaySettings) {
            displaySettings = JSON.stringify(res.stories.displaySettings, null, 2);
          }
          this.setState({
            types: res.types,
            book: res.stories.book,
            pageTitle: res.stories.title,
            uuid: res.stories.uuid,
            title: res.stories.title,
            displaySettings,
            revision: res.stories.revision,
            summary: res.stories.summary,
            story_role: res.stories.story_role,
            chapter: res.stories.chapter,
            public: res.stories.public,
            recommendations: res.stories.recommendations ? res.stories.recommendations : {
              decisionPoints: recDecisionPoints,
              choices: recChoices,
              bonus_content: recBonusContent,
              stats: [],
            },
            validated: false,
            formError: null,
            loading: false,
            displaySettingsError: null,
            episodeSummaryErrorLimit: null,
            // eslint-disable-next-line react/no-unused-state
            author: res.stories.author,
            // eslint-disable-next-line react/no-unused-state
            storyTags: res.tags,
            // eslint-disable-next-line react/no-unused-state
            tags: res.stories.tags,
            isBonus: res.stories.isBonus,
          });
        }
      } catch (e) {
        this.setState({
          loading: false,
        });
        if (e.response) {
          this.errorAlert(e.response.data.error);
        }
      }
    });
  }

  render() {
    const {
      update,
      type,
      show,
      user,
      onHide,
      limits,
      modeEdit,
      ...other
    } = this.props;
    const {
      validated,
      recommendations,
      episodeErrorLimit,
      displaySettings,
      chapter,
      formError,
      loading,
      pageTitle,
      summary,
      revision,
      uuid,
      types,
      displaySettingsError,
      story_role: storyRole,
      episodeSummaryErrorLimit,
      title,
      show: showFromState,
      public: isPublic,
      book,
      isBonus,
    } = this.state;

    const storyTitleMax = Number(limits.story_title_max.value);
    const storySummaryMax = Number(limits.story_summary_max.value);

    const {
      isAdmin,
      isUserHasMemoryBankAccess,
      isBonusFieldTitle,
    } = getAddStoryUserAccess(user?.role, true);

    if (show === true) {
      return (
        <Modal
          show={show}
          user={user}
          onHide={onHide}
          limits={limits}
          {...other}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Form
            noValidate
            validated={validated}
            onSubmit={(e) => this.handleSubmit(e)}
          >

            <Modal.Header closeButton>
              <Modal.Title>{loading === false ? `Edit episode: "${pageTitle}"` : null}</Modal.Title>
            </Modal.Header>

            <Modal.Body>

              <Spinner
                animation="border"
                variant="primary"
                className={loading !== false ? 'loadingSpinner justify-content-center' : 'd-none '}
              />

              <Col md={12} className={formError === null ? 'd-none' : 'd-block'}>
                <Alert variant="danger">
                  {formError}
                </Alert>
              </Col>

              <Form.Row>
                <Form.Group as={Col} controlId="StoriesTitle">
                  <Form.Label>Episode Title</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Episode Title"
                    pattern="^([A-Za-z]|[0-9]|_|-| |[.!?,:;()&|[\]\/@]|'|\u0022)+$"
                    name="title"
                    defaultValue={title}
                    className={episodeErrorLimit ? 'text-limit' : null}
                    onChange={(e) => {
                      const { value } = e.target;

                      this.setState({
                        episodeErrorLimit: value && value.length > storyTitleMax,
                        title: value,
                      });
                    }}
                  />
                  <Form.Text className="char-limit-info-box">
                    {storyTitleMax - (title ? title.length : 0)}
                  </Form.Text>

                  <Form.Control.Feedback
                    type="invalid"
                    className={episodeErrorLimit ? 'd-block' : 'd-none'}
                  >
                    Episode Title is too long.
                  </Form.Control.Feedback>

                  <Form.Control.Feedback type="invalid">
                    Please choose a title.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>

                <Form.Group as={Col} controlId="StorySummary">
                  <Form.Label>Episode Description</Form.Label>
                  <Form.Control
                    required
                    as="textarea"
                    rows="1"
                    maxLength="280"
                    placeholder="Episode Description"
                    name="summary"
                    isInvalid={validated && summary.match(/^\s*$/)}
                    value={summary}
                    className={episodeSummaryErrorLimit ? 'text-limit' : null}
                    onChange={(e) => {
                      const { value } = e.target;
                      this.setState({
                        episodeSummaryErrorLimit: value && value.length > storySummaryMax,
                        summary: value,
                      });
                    }}
                  />
                  <Form.Text className="char-limit-info-box">
                    {storySummaryMax - (summary ? summary.length : 0)}
                  </Form.Text>
                  <Form.Control.Feedback
                    type="invalid"
                    className={episodeSummaryErrorLimit ? 'd-block' : 'd-none'}
                  >
                    Episode Description is too long.
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please choose a description.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                {isUserHasMemoryBankAccess && (
                  <BonusFormGroup
                    title={isBonusFieldTitle}
                    value={Boolean(isBonus)}
                    onChange={(value) => this.setState({ isBonus: value })}
                    disabled={!isAdmin}
                  />
                )}
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md={4} controlId="StoryChapter">
                  <Form.Label>Episode #</Form.Label>

                  {isBonus
                    ? (
                      <div>
                        This is a bonus episode.
                      </div>
                    )
                    : (
                      <InputGroup>
                        <FormControl
                          required
                          type="text"
                          placeholder="Episode #"
                          name="chapter"
                          pattern="^[0-9]+$"
                          min={1}
                          value={chapter || ''}
                          onChange={(e) => {
                            this.setState({
                              chapter: e.target.value.replace(/[^0-9]/g, ''),
                            });
                          }}
                        />
                        <InputGroup.Append>
                          <Button
                            className="numInpBtn"
                            variant="outline-secondary"
                            onClick={() => {
                              this.setState({
                                chapter: chapter <= 1 ? 1 : Number(chapter) - 1,
                              });
                            }}
                          >
                            &#9660;
                          </Button>
                          <Button
                            className="numInpBtn"
                            variant="outline-secondary"
                            onClick={() => {
                              this.setState({
                                chapter: Number(chapter) + 1,
                              });
                            }}
                          >
                            &#9650;
                          </Button>
                        </InputGroup.Append>
                        <Form.Control.Feedback type="invalid">
                          Please choose an episode number.
                        </Form.Control.Feedback>
                      </InputGroup>
                    )}
                </Form.Group>

                <Form.Group as={Col} md={1} controlId="StoryVersion">
                  <Form.Label>Version</Form.Label>

                  <InputGroup>
                    <FormControl
                      type="text"
                      placeholder="Version"
                      name="version"
                      min={1}
                      disabled
                      value={revision || 1}
                    />
                  </InputGroup>
                </Form.Group>

              </Form.Row>

              {(user.role === 'admin') && (
              <Form.Row>
                <Form.Group as={Col} controlId="StoryUUID">
                  <Form.Label>Episode UUID</Form.Label>
                  <FormControl
                    type="text"
                    placeholder="Episode UUID"
                    disabled
                    value={uuid || ''}
                  />
                </Form.Group>
              </Form.Row>
              )}

              <OptionAdminRequirements
                user={user}
                recommendations={recommendations}
                types={types}
                show={showFromState}
                public={isPublic}
              />

              {(user && user.role === 'admin') && (
              <OptionDisplaySetings
                defaultValue={displaySettings}
                error={displaySettingsError}
              />
              )}

            </Modal.Body>

            <Modal.Footer>
              <Button
                type="reset"
                variant="secondary"
                onClick={() => {
                  onHide();
                }}
              >
                Cancel
              </Button>

              <Button
                disabled={(
                    (
                      user.role !== 'admin'
                      && storyRole !== 'owner'
                      && storyRole !== 'editor'
                      && storyRole !== 'co-author'
                    )
                    || loading
                    || !modeEdit
                    || (title && title.length > storyTitleMax)
                    || (summary && summary.length > storySummaryMax)
                    || PremiumIpDisabledEdit(user.role, book)
                    || PremiumIpDisabledApprovedEdit(user.role, book)
                  )}
                type="submit"
                variant="primary"
              >
                Save
              </Button>

            </Modal.Footer>
          </Form>
        </Modal>
      );
    }

    return null;
  }
}
