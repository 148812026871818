import { isAchievementsEnabled, isMemoryBankEnabled } from '../../utils/userActionUtils';

export function getAddStoryUserAccess(role: string, isEpisodeData: boolean) {
  const isAdmin = role === 'admin';
  const isExistingEpisodeEdit = Boolean(isEpisodeData);
  const isUserHasMemoryBankAccess = isMemoryBankEnabled() && isAchievementsEnabled();
  let isBonusFieldTitle = '';
  if (isAdmin) {
    isBonusFieldTitle = 'Mark as Bonus Episode';
  } else if (!isUserHasMemoryBankAccess) {
    isBonusFieldTitle = 'This feature is not available for your account';
  } else if (isExistingEpisodeEdit) {
    isBonusFieldTitle = 'You can\'t change this field for existing episodes';
  }
  return {
    isAdmin,
    isExistingEpisodeEdit,
    isUserHasMemoryBankAccess,
    isBonusFieldTitle,
  };
}
